import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'

import {
  Button,
  Check,
  Divider,
  Input,
  InputBase,
  InputContainer,
  InputHint,
  InputLabelContainer,
  InputTrailingContainer,
  Textarea,
  TextareaContainer,
  Toggle,
} from '@sweetspot/scramble-ds'
import ButtonDock from '@sweetspot/club-portal-legacy/components/ButtonDock/ButtonDock'

import { CurrentCourse, UpdateRangeData } from '../../types'
import useUpdateRange from '../../hooks/useUpdateRange'
import useRoutingBlock from '../../hooks/useRoutingBlock'

type PaymentFormFields = Pick<
  UpdateRangeData,
  'vat' | 'isCanPay' | 'isPayOnSiteEnabled' | 'payOnSiteTitle' | 'payOnSiteDescription'
>

type PaymentTabProps = {
  currentCourse?: CurrentCourse
  refetchCourses: () => void
}

const PaymentTab = ({ currentCourse, refetchCourses }: PaymentTabProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const { updateRange, paymentInitialData: initialData } = useUpdateRange({ currentCourse })
  const { register, watch, setValue, formState, reset } = useForm<PaymentFormFields>({
    defaultValues: initialData,
  })
  const formValues = watch()
  const isDirty = formState?.isDirty

  // reset form initialData
  useEffect(() => {
    reset(initialData)
  }, [initialData, reset])

  const onSubmit = useCallback(() => {
    updateRange(formValues)?.then(() => {
      addToast(t('toast.updateRangeSuccess'), { appearance: 'success' })
      reset(formValues)
      refetchCourses()
    })
  }, [formValues, updateRange, reset, refetchCourses, addToast, t])

  // block navigation when form is dirty
  useRoutingBlock({ isDirty, onReset: reset, onSubmit })

  return (
    <form onSubmit={(e) => e.preventDefault()} className="flex max-w-[398px] flex-col gap-6">
      <InputBase>
        <InputLabelContainer>{t('words.vat')}</InputLabelContainer>
        <InputContainer>
          <Input className="text-content-base h-8 border" {...register('vat')} />
          <InputTrailingContainer className="right-2">
            <p>%</p>
          </InputTrailingContainer>
        </InputContainer>
        <InputHint
          className="[&_i]:text-content-xs items-center [&_:first-child]:w-auto"
          variant="default"
          hintText={t('settings.range.vatHint')}
        />
      </InputBase>

      <Divider />

      <div className="flex min-h-12 items-center gap-4">
        <div>
          <label className="text-text-dark text-content-sm px-md font-bold">
            {t('settings.range.paymentRequiredInAppLabel')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.range.paymentRequiredInAppHint')}
          </div>
        </div>
        <Check
          className="pl-0 pr-1"
          checked={!!formValues.isCanPay}
          onCheckedChange={(newValue) => setValue('isCanPay', !!newValue, { shouldDirty: true })}
        />
      </div>

      <Divider />

      <div className="flex min-h-12 items-center gap-4">
        <div>
          <label className="text-text-dark text-content-sm px-md font-bold">
            {t('settings.range.enablePayOnSiteLabel')}
          </label>
          <div className="text-text-subtle text-content-sm pl-2">
            {t('settings.range.enablePayOnSiteHint')}
          </div>
        </div>
        <Toggle
          checked={!!formValues.isPayOnSiteEnabled}
          onCheckedChange={(newValue) =>
            setValue('isPayOnSiteEnabled', !!newValue, { shouldDirty: true })
          }
        />
      </div>

      {!!formValues.isPayOnSiteEnabled && (
        <>
          <InputBase>
            <InputLabelContainer>
              {t('settings.range.customPayOnSiteTitleLabel')}
            </InputLabelContainer>
            <InputContainer>
              <Input className="text-content-base h-8 border" {...register('payOnSiteTitle')} />
            </InputContainer>
          </InputBase>

          <InputBase>
            <InputLabelContainer className="mb-0.5">
              {t('settings.range.customPayOnSiteDescriptionLabel')}
            </InputLabelContainer>
            <TextareaContainer>
              <Textarea
                {...register('payOnSiteDescription')}
                max={undefined}
                min={undefined}
                className="text-content-base border"
              />
            </TextareaContainer>
          </InputBase>
        </>
      )}

      <ButtonDock className="max-w-[422px] px-0" dockClassName="left-[15px]" isShown={isDirty}>
        <Button variant="ghost-dark" size="small" type="button" onClick={() => reset()}>
          {t('words.cancel')}
        </Button>
        <Button variant="primary" size="small" onClick={onSubmit}>
          {t('words.save')}
        </Button>
      </ButtonDock>
    </form>
  )
}

export default PaymentTab
