import { FeatureFlagValue } from '@sweetspot/shared/data-access/api-platform'

export enum FlagNames {
  DemoSweetspotUser = 'demo-sweetspot-user',
  RangeBays = 'range-bays',
  WebTeeSheetBookings = 'wb-tee-sheet-bookings',
  PaymentLinks = 'payment-links',
  GitClubSelect = 'git-club-select',
}

// Add other types of flags if needed
export type Flag<T extends FlagNames> = Record<T, FeatureFlagValue>

export type FeatureFlags = Flag<FlagNames.DemoSweetspotUser> &
  Flag<FlagNames.RangeBays> &
  Flag<FlagNames.WebTeeSheetBookings> &
  Flag<FlagNames.PaymentLinks> &
  Flag<FlagNames.GitClubSelect>
