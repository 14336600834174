import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'

import {
  updateBucketConfiguration,
  queryBucketConfiguration,
  BucketConfigurationPayload,
  queryRangeSchedule,
  updateRangeSchedule,
  RangeSchedulePayload,
} from '@sweetspot/shared/data-access/api-platform'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'

import {
  BUCKET_DATA,
  BALL_PRICE_DATA,
  PRICE_ROUNDING_DATA,
  OPENING_HOURS_DEFAULT,
} from '../constants'
import { BallPrice, BucketSize, OpeningHours } from '../types'
import {
  constructBucketConfigurationData,
  constructBucketConfigurationPayload,
  constructScheduleData,
  constructSchedulePayload,
  validateBucketConfiguration,
} from '../utils'

const useBucketConfigurationSettings = ({
  rangeId,
  demandModelId,
  createDemandModel,
}: {
  rangeId?: string
  demandModelId?: string
  createDemandModel?: () => void
}) => {
  const [isDirty, setIsDirty] = useState(false)
  const [openingHours, setOpeningHours] = useState<OpeningHours>({})
  const [bucketSizes, setBucketSizes] = useState<BucketSize[]>([])
  const [ballPrice, setBallPrice] = useState<BallPrice>({})
  const [priceRounding, setPriceRounding] = useState<number>(1)
  const [trackingTechnologies, setTrackingTechnologies] = useState<string[]>([])

  const { addToast } = useToasts()
  const { t } = useTranslation()

  const handleSetOpeningHours = useCallback((newData: OpeningHours) => {
    setIsDirty(true)
    setOpeningHours((prev) => ({
      ...prev,
      ...Object.entries(newData).reduce(
        (acc, [day, values]) => ({
          ...acc,
          [day]: { ...prev[day], ...values },
        }),
        {}
      ),
    }))
  }, [])

  const handleAddBucketSize = useCallback(() => {
    setIsDirty(true)
    setBucketSizes((prev) => [
      ...prev,
      {
        nrOfBalls: 25,
        discount: 0,
      },
    ])
  }, [])

  const handleRemoveBucketSize = useCallback((index: number) => {
    setIsDirty(true)
    setBucketSizes((prev) => prev.filter((_, i) => i !== index))
  }, [])

  const handleSetBucketSizes = useCallback((newData: Partial<BucketSize>, index: number) => {
    setIsDirty(true)
    setBucketSizes((prev) => {
      const newSizes = [...prev]
      newSizes[index] = { ...newSizes[index], ...newData }
      return newSizes
    })
  }, [])

  const handleSetBallPrice = useCallback((newData: BallPrice) => {
    setIsDirty(true)
    setBallPrice((prev) => ({
      ...prev,
      ...Object.entries(newData).reduce(
        (acc, [tech, prices]) => ({
          ...acc,
          [tech]: { ...prev[tech], ...prices },
        }),
        {}
      ),
    }))
  }, [])

  const handleSetPriceRounding = useCallback((rounding: number) => {
    setIsDirty(true)
    setPriceRounding(rounding)
  }, [])

  const bucketConfigurationMutation = useMutation(
    (payload: BucketConfigurationPayload) => {
      return updateBucketConfiguration({
        rangeId,
        bucketConfiguration: payload,
      })
    },
    {
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const rangeScheduleMutation = useMutation(
    (payload: RangeSchedulePayload) => {
      return updateRangeSchedule({
        rangeId,
        schedule: payload,
      })
    },
    {
      onSuccess: () => {
        if (!demandModelId) {
          createDemandModel?.()
        }
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const handleOnSave = useCallback(
    async (cb: () => void) => {
      const bucketConfigPayload = constructBucketConfigurationPayload({
        bucketSizes,
        ballPrice,
        priceRounding,
      })
      const errorText = validateBucketConfiguration({
        ...bucketConfigPayload,
        opening_hours: openingHours,
      })

      if (errorText) {
        addToast(t(errorText), { appearance: 'error' })
      } else {
        const promises = [
          bucketConfigurationMutation.mutateAsync(bucketConfigPayload),
          rangeScheduleMutation.mutateAsync(constructSchedulePayload(openingHours)),
        ]
        Promise.all(promises).then(() => {
          setIsDirty(false)
          addToast(t('toast.updateBucketConfigurationSuccess'), { appearance: 'success' })
          cb()
        })
      }
    },
    [
      bucketConfigurationMutation,
      rangeScheduleMutation,
      ballPrice,
      bucketSizes,
      priceRounding,
      t,
      addToast,
      openingHours,
    ]
  )

  const {
    isFetching: isBucketConfigurationFetching,
    isFetched: isBucketConfigurationFetched,
    refetch: getBucketConfiguration,
  } = useQuery(
    [CLUB_QUERIES.BUCKET_CONFIGURATION, rangeId],
    () => queryBucketConfiguration(rangeId),
    {
      enabled: false,
      onSuccess: (data) => {
        const bucketConfigurationData = constructBucketConfigurationData(data)
        setBucketSizes(bucketConfigurationData.bucketSizes)
        setBallPrice(bucketConfigurationData.ballPrice)
        setPriceRounding(bucketConfigurationData.priceRounding)
        setTrackingTechnologies(bucketConfigurationData.trackingTechnologies)
        setIsDirty(false)
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  const {
    isFetching: isRangeScheduleFetching,
    isFetched: isRangeScheduleFetched,
    refetch: getRangeSchedule,
  } = useQuery(
    [CLUB_QUERIES.BUCKET_CONFIGURATION, CLUB_QUERIES.RANGE_SCHEDULE, rangeId],
    () => queryRangeSchedule(rangeId),
    {
      enabled: false,
      onSuccess: (data) => {
        const scheduleData = constructScheduleData(data)
        setOpeningHours(scheduleData)
        setIsDirty(false)
      },
      onError: () => {
        addToast(t('errors.somethingWentWrongNoId'), { appearance: 'error' })
      },
    }
  )

  return {
    openingHours,
    setOpeningHours: handleSetOpeningHours,
    openingHoursDefault: OPENING_HOURS_DEFAULT,
    bucketSizes,
    setBucketSizes: handleSetBucketSizes,
    addBucketSize: handleAddBucketSize,
    removeBucketSize: handleRemoveBucketSize,
    maxNrOfBalls: BUCKET_DATA.maxNrOfBalls,
    maxNrOfBuckets: BUCKET_DATA.maxNrOfBuckets,
    discountsOptions: BUCKET_DATA.discount,
    ballPrice,
    setBallPrice: handleSetBallPrice,
    ballPriceData: {
      demands: BALL_PRICE_DATA.demands,
      technologies: trackingTechnologies,
      maxBallPrice: BALL_PRICE_DATA.maxBallPrice,
    },
    priceRounding,
    setPriceRounding: handleSetPriceRounding,
    priceRoundingData: PRICE_ROUNDING_DATA,
    onSave: handleOnSave,
    getBucketConfiguration,
    getRangeSchedule,
    isUpdating: bucketConfigurationMutation.isLoading || rangeScheduleMutation.isLoading,
    isFetching: isBucketConfigurationFetching || isRangeScheduleFetching,
    isFetched: isBucketConfigurationFetched || isRangeScheduleFetched,
    isDirty,
  }
}

export default useBucketConfigurationSettings
