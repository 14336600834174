import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { UpdateRangePayload } from './types'

export const updateRange = (body: Pick<UpdateRangePayload, 'name' | 'id'>): Promise<void> => {
  const { id, ...rest } = body
  const url = `${getBaseUrlPlatform()}/ranges/${id}`
  return request(url, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: { ...rest },
  })
}
