import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faCalendarCheck, faTimer } from '@fortawesome/pro-light-svg-icons'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import {
  ListItem,
  ListItemMainContainer,
  ListItemMainContent,
  ListItemMainLabel,
  ListItemParagraph,
  ListItemTrailing,
} from '@sweetspot/scramble-ds'
import { PaymentStateTag } from '../../../../components/common/PaymentStateTag'
import { calculateCreatedDate, calculateTimeLeft } from '../../../../utils'
import { usePaymentIdStore } from '../../store'
import { useClubCurrency } from '@sweetspot/shared/util/hooks'
import { PaymentItemProps } from './types'

export const PaymentItem = ({ paymentLink }: PaymentItemProps) => {
  const { selectedPaymentId, setSelectedPaymentId } = usePaymentIdStore((state) => state)
  const [currencyCode] = useClubCurrency()

  const isUnpaid = paymentLink?.payment?.state === 'new'

  return (
    <div className="bg-background-base-clean flex flex-col gap-3 overflow-y-auto [&::-webkit-scrollbar]:hidden">
      <ListItem
        className={`min-h-[108px] w-full cursor-pointer pl-10 pr-2 ${
          selectedPaymentId === paymentLink?.uuid && 'bg-background-sand-light'
        }`}
        onClick={() => setSelectedPaymentId(paymentLink?.uuid)}
      >
        <ListItemMainContainer showDivider>
          <ListItemMainContent className="font-content pb-3">
            <ListItemMainLabel className="text-content-sm font-content">
              Ref: {paymentLink?.uuid}
            </ListItemMainLabel>
            <ListItemParagraph>
              <span className="text-content-xs text-[#6B7280]">{paymentLink.url}</span>
              <div className="mt-1 flex flex-row gap-2">
                <div className="flex flex-row text-xs">
                  <FontAwesomeIcon className="pr-1" icon={faCoins} />
                  <span className="pr-1">
                    {priceToLocal(paymentLink?.payment.amount, currencyCode, true)}
                  </span>
                </div>
              </div>
              <div className="mt-1 flex flex-row justify-between text-xs">
                <div className="mr-4 flex">
                  <FontAwesomeIcon className="pr-1" icon={faCalendarCheck} />
                  {calculateCreatedDate(paymentLink?.created_at)}
                </div>
                {isUnpaid && (
                  <div className="flex">
                    <FontAwesomeIcon className="pr-1" icon={faTimer} />
                    {paymentLink.expires_at && calculateTimeLeft(paymentLink.expires_at)}
                  </div>
                )}
              </div>
            </ListItemParagraph>
          </ListItemMainContent>
          <ListItemTrailing>
            <PaymentStateTag paymentLink={paymentLink} className="py-1" />
          </ListItemTrailing>
        </ListItemMainContainer>
      </ListItem>
    </div>
  )
}
