import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import z from 'zod'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeaderLeftIcon,
  SheetTitle,
  SheetHeader,
  SheetCustomContent,
  SheetFooter,
  Button,
  Hint,
  EmptyState,
  EmptyStateTitle,
  EmptyStateDescription,
  SheetClose,
} from '@sweetspot/scramble-ds'

import { useForm } from 'react-hook-form'
import { track } from '@amplitude/analytics-browser'
import { AMPLITUDE_EVENTS, CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { usePaymentLinkUpdate } from '../../hooks/usePaymentLinkUpdate'
import { zodResolver } from '@hookform/resolvers/zod'
import { SettingsFormScema } from '../../components/Settings/validation'
import {
  FirstPaymentPercentageAndAmount,
  FirstPaymentDuration,
  LastPaymentDuration,
  ToggleInput,
} from '../../components/Settings'
import { useSelector } from 'react-redux'

import { FIRST_PAYMENT_PAY } from '../../consts'
import { CourseSelect } from './CourseSelect'
import { useQuery } from 'react-query'
import { useToasts } from 'react-toast-notifications'
import {
  setFirstPaymentDurationFromSeconds,
  setLastPaymentDurationFromSeconds,
} from '../../components/Settings/utils'
import { secondsToDays } from '../../utils'
import { getCourseById } from '@sweetspot/shared/data-access/api-platform'
import { Venue } from '@sweetspot/shared/types'

export const PaymentCourseSettingsSheet = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const [selectedCourseName, setSelectedCourseName] = useState<string | undefined>(
    t('paymentLinks.selectCourse')
  )
  const [golfCourses, setGolfCourses] = useState<Venue[]>([])

  const courses = useSelector((state) => state.golfCourse.list)

  useEffect(() => {
    const coursesList = courses.filter((course: Venue) => course.type === 'course')

    setGolfCourses(coursesList)
  }, [courses])

  const currentCourseId = golfCourses?.find((course) => course.name === selectedCourseName)?.id

  const payment = usePaymentLinkUpdate(currentCourseId)

  const { data: currentCourse } = useQuery(
    [CLUB_QUERIES.COURSES, currentCourseId],
    () => {
      return getCourseById(currentCourseId)
    },
    {
      enabled: !!currentCourseId,
      onError: () => {
        addToast(t('sentences.couldNotLoadGolfCourse'), { appearance: 'error' })
      },
    }
  )

  const { down_payment_percent, first_payment_duration, second_payment_duration } =
    currentCourse?.payment_link_configuration || {}

  const { handleSubmit, control, watch, setValue, reset } = useForm<
    z.infer<typeof SettingsFormScema>
  >({
    resolver: zodResolver(SettingsFormScema),
    defaultValues: {
      partial_payments: down_payment_percent > 0,
      first_payment_pay: FIRST_PAYMENT_PAY.PERCENTAGE,
      percentage: down_payment_percent || 10,
      first_payment_duration: setFirstPaymentDurationFromSeconds(first_payment_duration),
      first_payment_duration_days: secondsToDays(first_payment_duration) || 1,
      last_payment_duration: setLastPaymentDurationFromSeconds(second_payment_duration),
      last_payment_duration_days: secondsToDays(second_payment_duration) || 30,
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (currentCourse) {
      const { down_payment_percent, first_payment_duration, second_payment_duration } =
        currentCourse.payment_link_configuration || {}

      reset({
        partial_payments: down_payment_percent > 0,
        first_payment_pay: FIRST_PAYMENT_PAY.PERCENTAGE,
        percentage: down_payment_percent || 10,
        first_payment_duration: setFirstPaymentDurationFromSeconds(first_payment_duration),
        first_payment_duration_days: secondsToDays(first_payment_duration) || 1,
        last_payment_duration: setLastPaymentDurationFromSeconds(second_payment_duration),
        last_payment_duration_days: secondsToDays(second_payment_duration) || 30,
      })
    }
  }, [currentCourse, reset])

  const handleSaveClick = () => {
    track({
      event_type: AMPLITUDE_EVENTS.PAYMENT_LINKS.CONFIGURATION_SAVED,
      event_properties: {
        [AMPLITUDE_EVENTS.PAYMENT_LINKS.SPLIT_PAYMENTS_ENABLED]: partial_payments ? 'Yes' : 'No',
        ...(partial_payments && {
          [AMPLITUDE_EVENTS.PAYMENT_LINKS.FIRST_PAYMENT]: `${first_payment_duration_days} Days`,
        }),
        [AMPLITUDE_EVENTS.PAYMENT_LINKS.FINAL_PAYMENT]: `${last_payment_duration_days} Days`,
      },
    })
    handleSubmit(payment.updateConfiguration)()
  }

  const { partial_payments, first_payment_duration_days, last_payment_duration_days } = watch()

  return (
    <Sheet>
      <SheetTrigger asChild className="cursor-pointer">
        {children}
      </SheetTrigger>
      <SheetContent disableOverlay>
        <SheetHeader>
          <SheetClose asChild>
            <SheetHeaderLeftIcon
              onClick={(e) => {
                if (selectedCourseName !== t('paymentLinks.selectCourse')) {
                  e.preventDefault()
                }
                setSelectedCourseName(t('paymentLinks.selectCourse'))
              }}
            >
              <i className="fa-regular fa-arrow-left" />
            </SheetHeaderLeftIcon>
          </SheetClose>
          <SheetTitle className="text-lg">{t('paymentLinks.courseSettings')}</SheetTitle>
        </SheetHeader>

        <SheetCustomContent>
          {selectedCourseName !== t('paymentLinks.selectCourse') ? (
            <>
              <div className="mb-3 flex w-full flex-row gap-3"></div>
              <ToggleInput
                control={control}
                name="partial_payments"
                text={t('paymentLinks.acceptPartialPayment')}
                infoText={t('paymentLinks.splitTotalAmount')}
              />
              {partial_payments && (
                <div className="mb-lg flex w-full flex-col">
                  <p className="text-content-sm mb-xs font-bold leading-5">
                    {t('paymentLinks.firstPayment')}
                  </p>
                  <div className="bg-background-mono-lighter border-stroke-pale flex flex-col rounded-md border-2">
                    <FirstPaymentPercentageAndAmount
                      control={control}
                      setValue={setValue}
                      hideAmount
                    />
                    <FirstPaymentDuration control={control} setValue={setValue} />
                  </div>
                </div>
              )}
              <div className="mb-lg flex w-full flex-col">
                <p className="text-content-sm mb-xs font-bold leading-5">
                  {t('paymentLinks.finalPayment')}
                </p>

                <div className="bg-background-mono-lighter border-stroke-pale flex flex-col rounded-md border-2">
                  <LastPaymentDuration control={control} setValue={setValue} />
                </div>
              </div>
              <Hint hintText={t('paymentLinks.noteText')} variant="default" />
            </>
          ) : (
            <>
              <div className="flex w-full flex-row gap-3">
                <CourseSelect
                  setSelectedCourse={setSelectedCourseName}
                  selectedCourse={selectedCourseName}
                  golfCourses={golfCourses}
                />
              </div>
              <div className="mt-44">
                <EmptyState className="max-w-full" iconName="fa-list-dropdown">
                  <EmptyStateTitle>{t('paymentLinks.selectCourse')}</EmptyStateTitle>
                  <EmptyStateDescription className="mx-6">
                    {t('paymentLinks.startConfiguringSettings')}
                  </EmptyStateDescription>
                </EmptyState>
              </div>
            </>
          )}
        </SheetCustomContent>

        {selectedCourseName !== t('paymentLinks.selectCourse') && (
          <SheetFooter>
            <Button className="w-full" onClick={handleSaveClick} disabled={payment.isLoading}>
              {t('paymentLinks.saveGlobalSettings')}
            </Button>
          </SheetFooter>
        )}
      </SheetContent>
    </Sheet>
  )
}
