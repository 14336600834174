import {
  parseISO,
  format,
  intervalToDuration,
  add,
  differenceInDays,
  subHours,
  isValid,
} from 'date-fns'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import { SECONDS_IN_DAY } from './consts'
import { Booking, PaymentLink } from '@sweetspot/shared/types'
import { BookingFields } from './types'

export const calculateAmount = (amount: string, percentage: number) => {
  const validPercentage = Math.min(Math.max(percentage, 0), 100)

  return Math.round(Number(amount) * (validPercentage / 100))
}

export const calculateCreatedDate = (createdAtDate: string) => {
  try {
    const parsedDate = parseISO(createdAtDate)
    if (!isValid(parsedDate)) {
      throw new RangeError('Invalid time value')
    }
    return format(parsedDate, 'd MMM yyyy - HH:mm')
  } catch (error) {
    return 'Invalid Date'
  }
}

export const calculatePercentage = (amount: string, value: number) => {
  const numericAmount = Number(amount)

  if (numericAmount === 0) {
    return 0
  }

  return Math.round((value / numericAmount) * 100)
}

export const calculateTimeLeft = (futureDateString: string) => {
  const now = new Date()
  const futureDate = parseISO(futureDateString)

  if (futureDate <= now) {
    return '0m Left'
  }

  const adjustedFutureDate = subHours(futureDate, 2)
  const duration = intervalToDuration({ start: now, end: adjustedFutureDate })

  const durationParts = []

  if (duration.months) {
    durationParts.push(`${duration.months}m`)
  }

  if (duration.days) {
    durationParts.push(`${duration.days}d`)
  }

  if (duration.hours) {
    durationParts.push(`${duration.hours}h`)
  }

  durationParts.push(`${duration.minutes}m Left`)

  return durationParts.join(' ')
}
export const copyToClipboard = ({
  text,
  onSuccess,
  onError,
}: {
  text: string
  onSuccess: () => void
  onError?: () => void
}) => {
  navigator.clipboard.writeText(text).then(onSuccess).catch(onError)
}

export const splitCurrencyAmount = (priceString: string) => {
  const price = priceString.trim()

  const amount = price.match(/\d+(?:\.\d+)?/)?.[0] || ''
  const currency = price.replace(amount, '').trim()

  return { amount, currency }
}

export const extractBookingFields = (booking: Booking) => {
  const paymentId =
    booking?.payments?.find((payment) => payment.state === 'new' || payment.state === 'processing')
      ?.uuid ?? ''
  const ownerItem = booking?.items?.find((item) => item.slot?.is_owner)

  const ownerEmail = ownerItem?.slot?.player?.email ?? ownerItem?.slot?.stub_player?.email ?? ''
  const ownerPhone = ownerItem?.slot?.player?.phone ?? ownerItem?.slot?.stub_player?.phone ?? ''

  const totalUnpaidAmount = booking.payments
    .filter((payment) => payment.state === 'new' || payment.state === 'processing')
    .reduce((total, payment) => total + payment.amount, 0)

  const priceString = priceToLocal(totalUnpaidAmount, booking?.currency_code, false)
  const { currency, amount } = splitCurrencyAmount(priceString)

  return { paymentId, ownerEmail, ownerPhone, currency, amount } as BookingFields
}

export const formatDeliveryMethod = (email: string, phone: { phone_number: string }) => {
  let result = ''

  if (email && phone && phone.phone_number.length !== 0) {
    result = `Email & SMS (${phone.phone_number})`
  } else if (email) {
    result = 'Email'
  } else if (phone && phone.phone_number.length !== 0) {
    result = `SMS (${phone.phone_number})`
  }

  return result
}

export const secondsToDays = (seconds: number) => {
  return seconds / SECONDS_IN_DAY
}

export const calculateExpiringDate = (days: number) => {
  return format(add(new Date(), { days: days }), 'yyyy-MM-dd HH:mm:ss')
}

export const calculateTotalAmount = (paymentResults: PaymentLink[], currencyCode: string) => {
  const totalAmount = paymentResults.reduce(
    (acc, result) => acc + (result?.payment?.amount || 0),
    0
  )

  const priceString = priceToLocal(totalAmount, currencyCode, false)
  const { currency, amount } = splitCurrencyAmount(priceString)

  return { currency, amount }
}

export const calculateDuration = (createdAt: string, expiresAt: string) => {
  const startDate = parseISO(createdAt)
  const endDate = parseISO(expiresAt)

  const daysDifference = differenceInDays(endDate, startDate)

  return `${daysDifference} day${daysDifference !== 1 ? 's' : ''}`
}

export const getTagVariant = (
  state: string,
  isExpired: boolean | undefined,
  isActive: boolean | undefined
) => {
  if (state === 'new') {
    return isExpired || !isActive ? 'danger-filled' : 'warning-filled'
  }
  if (state === 'completed') {
    return 'success-filled'
  }
  if (state === 'canceled') {
    return 'danger-filled'
  }
  return 'warning-filled'
}

export const getTagLabel = (
  state: string,
  isExpired: boolean | undefined,
  isActive: boolean | undefined,
  t: (key: string) => string
) => {
  if (state === 'new') {
    if (isExpired) return t('words.expired')
    if (!isActive) return t('paymentLinks.deactivated')
    return t('words.unpaid')
  }
  if (state === 'completed') return t('words.paid')
  if (state === 'refunded') return t('words.refunded')
  if (state === 'canceled') return t('words.canceled')
  return state
}
