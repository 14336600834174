import { Range } from '@sweetspot/shared/types'

export type BallPricingContextValue = {
  areBallPricingLoading: boolean
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  bucketConfiguration: BucketConfiguration
  demandModel: DemandModelValues
}

export type BucketConfiguration = {
  openingHours: OpeningHours
  setOpeningHours?: (openingHours: OpeningHours) => void
  openingHoursDefault?: { open?: string; close?: string }
  bucketSizes: BucketSize[]
  setBucketSizes?: (bucketSize: Partial<BucketSize>, index: number) => void
  addBucketSize?: () => void
  removeBucketSize?: (index: number) => void
  maxNrOfBalls: number
  maxNrOfBuckets: number
  discountsOptions: number[]
  ballPrice: BallPrice
  setBallPrice?: (ballPrice: BallPrice) => void
  ballPriceData?: {
    technologies: string[]
    demands?: Exclude<DemandStatus, DemandStatus.Closed>[]
    maxBallPrice?: number
  }
  priceRounding?: number
  setPriceRounding?: (priceRounding: number) => void
  priceRoundingData?: number[]
  onSave?: (cb: () => void) => void
  getBucketConfiguration?: () => void
  getRangeSchedule?: () => void
  isFetching?: boolean
  isFetched?: boolean
  isUpdating?: boolean
  isDirty?: boolean
}

export type OpeningHours = {
  [key: string]: { open?: string; close?: string; disabled?: boolean }
}

export type BucketSize = {
  nrOfBalls: number
  discount: number
}

export enum DemandStatus {
  High = 'high',
  Medium = 'mid',
  Low = 'low',
  Closed = 'closed',
}

export type BallPrice = {
  [key: string]: {
    [DemandStatus.Low]?: number
    [DemandStatus.Medium]?: number
    [DemandStatus.High]?: number
  }
}

export type DemandModelValues = {
  isDemandModelFetching?: boolean
  isRangeScheduleFetching?: boolean
  openingHours: OpeningHours
  timeSections: string[][]
  statusMatrix: StatusObject[][]
  DAYS_OF_WEEK: string[]
  handleStatusChange?: (rowIndex: number, colIndex: number, newStatus: DemandStatus) => void
  refetchRangeSchedule?: () => void
  refetchDemandModel?: () => void
  createDemandModel?: () => void
  demandModelId?: string
  isDemandModelError?: boolean
}

export type StatusObject = {
  status: DemandStatus
}
