import { useState } from 'react'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'
import { BayType } from '@sweetspot/shared/types'

type UseBaysProps = {
  selectedRangeId?: string
}

const useBays = ({ selectedRangeId }: UseBaysProps) => {
  const [selectedBays, setSelectedBays] = useState<BayType[]>([])

  const {
    data,
    isLoading: areBaysLoading,
    isFetching: areBaysFetching,
    refetch,
  } = useQuery(
    [
      CLUB_QUERIES.BAYS,
      {
        drivingRangeUuid: selectedRangeId,
      },
    ],
    () =>
      queryBays({
        drivingRangeId: selectedRangeId || '',
      }),
    {
      enabled: !!selectedRangeId,
    }
  )

  return {
    bays: (data as { bays: BayType[] })?.bays || [],
    areBaysLoading,
    areBaysFetching,
    selectedBays,
    setSelectedBays,
    refetch,
  }
}

export default useBays
