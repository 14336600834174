import { useCallback, useMemo, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { _updateGolfCourse as updateCourse } from '@sweetspot/club-portal-legacy/services/golfCourseApi'
import { UpdateRangeOldPayload } from '@sweetspot/sweetspot-js/features/ranges/services'
import { Venue, ViolationError } from '@sweetspot/shared/types'
import { _uploadMedia } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { RootState } from '@sweetspot/club-portal-legacy/store/types'
import {
  _uploadGolfCourseImage,
  _getGolfCourseImages,
} from '@sweetspot/club-portal-legacy/services/golfCourseApi'

import { CurrentCourse, RangeTabs, UpdateRangeData, UploadImageType } from '../types'
import { isViolationError } from '../utils'
import { DAY_MS } from '@sweetspot/sweetspot-js/common/constants/autoCancelSettingsOptions'
import { DEFAULT_CANCEL_POINT } from '../constants'
import { updateRange, UpdateRangePayload } from '@sweetspot/shared/data-access/api-platform'
import { useManageRangeContext } from '../ManageRangeProvider'
import { queryCourses } from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import { CONSTANTS } from '@sweetspot/club-portal-legacy/store/constants'

type UseUpdateRangeProps = {
  currentCourse?: CurrentCourse
}

const useUpdateRange = ({ currentCourse }: UseUpdateRangeProps) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const [isImageLoading, setIsImageLoading] = useState(false)
  const token = useSelector((state: RootState) => state?.auth?.token)
  const { selectedRange } = useManageRangeContext()
  const generalInitialData = useMemo(
    () => ({
      name: currentCourse?.name,
      description: currentCourse?.description,
      timezone: currentCourse?.timezone,
      latitude: currentCourse?.lonlat.latitude || '',
      longitude: currentCourse?.lonlat.longitude || '',
      memberships: currentCourse?.membership_sign_up_settings?.memberships ?? [],
    }),
    [currentCourse]
  )

  const bookingInitialData = useMemo(() => {
    const isNonConfirmedBookingAutoCancellationEnabled =
      currentCourse?.booking_settings?.auto_cancellation_settings?.non_confirmed_booking_settings
        ?.is_enabled ?? false
    const isUnpaidBookingAutoCancellationEnabled =
      currentCourse?.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
        ?.is_enabled ?? false
    const unpaidBookingAutoCancellationDays = isUnpaidBookingAutoCancellationEnabled
      ? (currentCourse?.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
          ?.duration as number) / DAY_MS
      : 0
    const isUnpaidBookingAutoCancellationDurationDays =
      unpaidBookingAutoCancellationDays && unpaidBookingAutoCancellationDays >= 1

    return {
      displayTeeTimesDays: currentCourse?.display_tee_time_days,
      bookingCancellationLimitHours:
        currentCourse?.booking_cancellation_limit_hours ?? DEFAULT_CANCEL_POINT,
      nonConfirmedBookingAutoCancellationEnabled: isNonConfirmedBookingAutoCancellationEnabled,
      nonConfirmedBookingAutoCancellationDuration:
        currentCourse?.booking_settings?.auto_cancellation_settings?.non_confirmed_booking_settings
          ?.duration,
      unpaidBookingAutoCancellationEnabled: isUnpaidBookingAutoCancellationEnabled,
      unpaidBookingAutoCancellationDuration: isUnpaidBookingAutoCancellationDurationDays
        ? null
        : currentCourse?.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
            ?.duration,
      unpaidBookingAutoCancellationDays,
      bookingInformation: currentCourse?.booking_information ?? '',
      importantBookingInformation: currentCourse?.important_booking_information ?? '',
      customEmailInformation: currentCourse?.custom_email_information ?? '',
    }
  }, [currentCourse])

  const paymentInitialData = useMemo(
    () => ({
      vat: currentCourse?.vat,
      isCanPay: currentCourse?.is_can_pay,
      isPayOnSiteEnabled: currentCourse?.is_pay_on_site_enabled,
      payOnSiteTitle: currentCourse?.pay_on_site_title,
      payOnSiteDescription: currentCourse?.pay_on_site_description,
    }),
    [currentCourse]
  )

  const checkForErrors = useCallback(
    (tab: RangeTabs, payload: UpdateRangeData) => {
      if (tab === 'booking') {
        if (
          payload.unpaidBookingAutoCancellationEnabled &&
          !payload.unpaidBookingAutoCancellationDuration &&
          (!payload.unpaidBookingAutoCancellationDays ||
            (payload.unpaidBookingAutoCancellationDays as number) < 1 ||
            (payload.unpaidBookingAutoCancellationDays as number) > 90)
        ) {
          return t('toast.unpaidBookingAutoCancellationDaysError', { this: 1, that: 365 })
        }
        if (
          payload.importantBookingInformation &&
          payload.importantBookingInformation.length > 120
        ) {
          return t('toast.importantBookingInfoMaxError', { this: 120 })
        }
      }
      return ''
    },
    [t]
  )

  const syncCourseDataWithReduxStore = useCallback(async () => {
    // the corresponding course needs a bit time to be created after the range is created
    setTimeout(async () => {
      const courses: Venue[] = await queryCourses({ uuid: currentCourse?.uuid })
      const updatedCourse = courses && courses[0]
      dispatch({
        type: CONSTANTS.GOLF_COURSE.UPDATE,
        payload: updatedCourse,
      })
    }, 2000)
  }, [currentCourse?.uuid, dispatch])

  const updateRangeMutation = useMutation(
    (data: UpdateRangeOldPayload) => updateCourse(currentCourse?.id, data),
    {
      onError: (error: { error: string } | ViolationError) => {
        const errorObj = (error as ViolationError)?.violations?.[0]
        const defaultErrorMessage = errorObj
          ? `${errorObj.propertyPath}: ${errorObj.message}`
          : t('toast.defaultError')
        addToast(defaultErrorMessage, { appearance: 'error' })
      },
    }
  )

  const updateRangeNameMutation = useMutation((data: UpdateRangePayload) => updateRange(data), {
    onError: (error: { error: string } | ViolationError) => {
      const errorObj = (error as ViolationError)?.violations?.[0]
      const defaultErrorMessage = errorObj
        ? `${errorObj.propertyPath}: ${errorObj.message}`
        : t('toast.defaultError')
      if (isViolationError(error)) {
        addToast(defaultErrorMessage, { appearance: 'error' })
      } else {
        if (error?.error?.includes('name: Range with this name already exists.')) {
          addToast(t('sentences.rangeNameAlreadyExists'), { appearance: 'error' })
        } else {
          addToast(defaultErrorMessage, { appearance: 'error' })
        }
      }
    },
  })

  const getImage = useCallback(async () => {
    setIsImageLoading(true)
    const images = (await _getGolfCourseImages(currentCourse?.id)) as UploadImageType[]
    setIsImageLoading(false)
    let image = ''
    if (images) {
      const lastIndex = images.length - 1
      image =
        images[lastIndex]?.content_url ||
        images[lastIndex]?.media_object?.content_url ||
        images[lastIndex]?.url ||
        ''
    }
    return image
  }, [currentCourse?.id])

  const uploadImage = useCallback(
    async (image: File) => {
      const media = new FormData()
      media.append('file', image)

      setIsImageLoading(true)
      const [res, err] = await to(_uploadMedia(token, media))
      setIsImageLoading(false)
      if (err) {
        addToast(t('toast.uploadImageError'), { appearance: 'error' })
        return null
      }
      if (res) {
        return res as UploadImageType
      }
      return null
    },
    [token, addToast, t]
  )

  const saveImage = useCallback(
    async (id: number) => {
      const courseId = currentCourse?.id
      _uploadGolfCourseImage(courseId, id).catch(() => {
        addToast(t('toast.uploadImageError'), { appearance: 'error' })
      })
    },
    [currentCourse?.id, addToast, t]
  )

  const handleUpdateRange = useCallback(
    async (data: UpdateRangeData) => {
      if (!currentCourse) return
      const displayTeeTimesDays = data.displayTeeTimesDays ?? currentCourse.display_tee_time_days
      const isPayOnSiteEnabled = data.isPayOnSiteEnabled ?? currentCourse.is_pay_on_site_enabled
      const payOnSiteTitle = data.payOnSiteTitle ?? currentCourse.pay_on_site_title ?? ''
      const payOnSiteDescription =
        data.payOnSiteDescription ?? currentCourse.pay_on_site_description ?? ''
      const nonConfirmedBookingAutoCancellationEnabled =
        data.nonConfirmedBookingAutoCancellationEnabled ??
        currentCourse.booking_settings?.auto_cancellation_settings?.non_confirmed_booking_settings
          ?.is_enabled ??
        false
      const nonConfirmedBookingAutoCancellationDuration =
        data.nonConfirmedBookingAutoCancellationDuration ??
        currentCourse.booking_settings?.auto_cancellation_settings?.non_confirmed_booking_settings
          ?.duration
      const unpaidBookingAutoCancellationEnabled =
        data.unpaidBookingAutoCancellationEnabled ??
        currentCourse.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
          ?.is_enabled ??
        false
      const isUnpaidBookingAutoCancellationDurationDays =
        currentCourse.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
          ?.duration /
          DAY_MS >=
        1
      const unpaidBookingAutoCancellationDuration =
        data.unpaidBookingAutoCancellationDuration ??
        (isUnpaidBookingAutoCancellationDurationDays
          ? null
          : currentCourse.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
              ?.duration)
      const unpaidBookingAutoCancellationDays =
        data.unpaidBookingAutoCancellationDays ??
        (isUnpaidBookingAutoCancellationDurationDays
          ? currentCourse.booking_settings?.auto_cancellation_settings?.unpaid_booking_settings
              ?.duration / DAY_MS
          : null)
      const memberships = data.memberships ?? currentCourse.membership_sign_up_settings?.memberships
      const latitude = typeof data.latitude === 'string' ? parseFloat(data.latitude) : data.latitude
      const longitude =
        typeof data.longitude === 'string' ? parseFloat(data.longitude) : data.longitude
      const vat = typeof data.vat === 'string' ? parseFloat(data.vat) : data.vat

      const payload: UpdateRangeOldPayload = {
        cdh_id: currentCourse.cdh_id,
        git_id: currentCourse.git_id,
        name: data.name ?? currentCourse.name,
        longitude: longitude ?? currentCourse.lonlat?.longitude ?? null,
        latitude: latitude ?? currentCourse.lonlat?.latitude ?? null,
        description: data.description ?? currentCourse.description,
        display_tee_time_days:
          displayTeeTimesDays === '' ? null : parseInt(displayTeeTimesDays as string),
        custom_email_information:
          data.customEmailInformation ?? currentCourse.custom_email_information ?? '',
        important_booking_information:
          data.importantBookingInformation ?? currentCourse.important_booking_information ?? '',
        booking_information: data.bookingInformation ?? currentCourse.booking_information ?? '',
        is_active: data.isActive ?? currentCourse.is_active,
        is_can_pay: data.isCanPay ?? currentCourse.is_can_pay,
        is_arrival_registration:
          (data.isArrivalRegistration ?? currentCourse.is_arrival_registration) || false,
        is_arrival_registration_after_schedule:
          data.isArrivalRegistrationAfterSchedule ??
          currentCourse.is_arrival_registration_after_schedule,
        is_stub_players_enabled: currentCourse.is_stub_players_enabled,
        is_pay_on_site_enabled: isPayOnSiteEnabled,
        pay_on_site_title: isPayOnSiteEnabled ? payOnSiteTitle : '',
        pay_on_site_description: isPayOnSiteEnabled ? payOnSiteDescription : '',
        timezone: data.timezone ?? currentCourse.timezone,
        membership_sign_up_settings: {
          is_enabled: !!memberships.length,
          memberships,
        },
        type: data.type ?? currentCourse.type,
        vat: vat ?? currentCourse.vat,
        non_confirmed_booking_auto_cancellation_enabled: nonConfirmedBookingAutoCancellationEnabled,
        non_confirmed_booking_auto_cancellation_duration: nonConfirmedBookingAutoCancellationEnabled
          ? nonConfirmedBookingAutoCancellationDuration
          : null,
        unpaid_booking_auto_cancellation_enabled: unpaidBookingAutoCancellationEnabled,
        unpaid_booking_auto_cancellation_duration:
          unpaidBookingAutoCancellationEnabled && unpaidBookingAutoCancellationDuration !== 0
            ? unpaidBookingAutoCancellationDuration
            : undefined,
        unpaid_booking_auto_cancellation_days:
          unpaidBookingAutoCancellationEnabled &&
          unpaidBookingAutoCancellationDays !== '' &&
          !unpaidBookingAutoCancellationDuration
            ? unpaidBookingAutoCancellationDays
            : undefined,
        booking_cancellation_limit_hours:
          data.bookingCancellationLimitHours ??
          currentCourse.booking_cancellation_limit_hours ??
          DEFAULT_CANCEL_POINT,
        booking_type: currentCourse.booking_type,
        is_use_dynamic_pricing: currentCourse.is_use_dynamic_pricing,
      }

      if (data.imageId) {
        saveImage(data.imageId)
      }

      const promises = [updateRangeMutation.mutateAsync(payload)]
      if (data.name && selectedRange?.id) {
        promises.push(
          updateRangeNameMutation.mutateAsync({ id: selectedRange.id, name: data.name })
        )
      }
      const responses = await Promise.all(promises)
      syncCourseDataWithReduxStore()
      return responses
    },
    [
      currentCourse,
      updateRangeMutation,
      selectedRange?.id,
      syncCourseDataWithReduxStore,
      saveImage,
      updateRangeNameMutation,
    ]
  )

  return {
    updateRange: handleUpdateRange,
    generalInitialData,
    bookingInitialData,
    paymentInitialData,
    checkForErrors,
    uploadImage,
    getImage,
    saveImage,
    isImageLoading,
  }
}

export default useUpdateRange
