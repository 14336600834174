import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import {
  getElementDimensions,
  getElementRect,
} from '@sweetspot/sweetspot-js/common/functions/utils'
import { priceToLocal } from '@sweetspot/shared/util/functions'
import moment from 'moment'
import Portal from '@sweetspot/sweetspot-js/common/components/Portal'
import { useHistory } from 'react-router'
import useOnClickOutside from '@sweetspot/sweetspot-js/common/hooks/useOnClickOutside'

import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import PlayerName from '@sweetspot/club-portal-legacy/components/PlayerName'

const BookingPreviewBox = ({
  booking,
  visible,
  venue,
  containerRef,
  onClosePreview,
  isBay,
  bays,
}) => {
  const { t } = useTranslation()
  let history = useHistory()
  const [position, setPosition] = useState({ top: 0, left: 0 })

  const boxRef = useRef(null)

  useOnClickOutside(boxRef, () => onClosePreview())

  useLayoutEffect(() => {
    const { top, left, right } = getElementRect(containerRef.current)
    const { height: boxHeight } = getElementDimensions(boxRef.current)
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth

    const elementIsOutsideBottomEdge = top + boxHeight > windowHeight
    const elementIsOutsideRightEdge = right + 288 > windowWidth

    const position = {
      ...(elementIsOutsideBottomEdge ? { bottom: 10 } : { top }),
      ...(elementIsOutsideRightEdge
        ? { left: left - 2 - 288, transformOrigin: 'top right' }
        : { left: right + 2 }),
    }

    setPosition(position)
  }, [containerRef, boxRef, visible])

  const isMember = useMemo(() => {
    return booking?.items?.some((item) => item.slot?.is_member) || false
  }, [booking])

  const venueTimeZone = useMemo(() => {
    return venue?.timezone || null
  }, [venue])

  const customer = useMemo(() => {
    return booking?.customer || null
  }, [booking])

  const partnership = useMemo(() => {
    return booking?.partnership || null
  }, [booking])

  const stubName = useMemo(() => {
    if (!booking?.stub_player) return null
    const { name, email, phone } = booking.stub_player
    return name || email || phone || null
  }, [booking])

  const currencyCode = useMemo(() => {
    return booking?.currency_code
  }, [booking])

  const total = useMemo(() => {
    if (!booking) return 0
    return priceToLocal(booking.total, currencyCode, true)
  }, [booking, currencyCode])

  const goToBooking = () => {
    history.push(`/simulators-ranges/orders/${booking.uuid}`)
  }

  const spaces = useMemo(() => {
    const result = []
    if (booking && booking?.spaces) {
      booking?.spaces?.forEach((space) => {
        const total = booking.items.reduce((accu, item) => {
          if (item?.slot?.tee_time?.space?.id === space.id) {
            return accu + item.total
          } else {
            return accu
          }
        }, 0)
        const bay = bays?.find((bay) => bay.uuid === space.uuid)
        const name = isBay && bay ? `${t('words.bay')} ${bay.bay_number}` : space.name

        const spaceObj = {
          id: space.id,
          name,
          total: priceToLocal(total, booking?.currency_code, true),
        }
        result.push(spaceObj)
      })
    }

    return result
  }, [booking, bays, isBay, t])

  return (
    <Portal id={'booking-preview-box'}>
      <div
        className={cx(styles.container, visible && styles.visible)}
        style={{ position: 'fixed', ...position }}
        onClick={(e) => e.stopPropagation()}
        ref={boxRef}
      >
        {!booking ? (
          <div className={styles.loading}>
            <PulseLoader showIf={true} dotClassName={styles.dot} />
          </div>
        ) : (
          <React.Fragment>
            <div className={cx(styles.header)}>
              <p>
                {moment(booking.booking.start_time).tz(venueTimeZone).format('HH:mm')}-
                {moment(booking.booking.end_time).tz(venueTimeZone).format('HH:mm')}
              </p>
              <span onClick={goToBooking} className={cx('material-icons md-light md-18')}>
                launch
              </span>
            </div>

            <div className={cx(styles.inner)}>
              <div className={cx(styles.row)}>
                <p className={cx(styles.text, styles.black, 'overflow-hidden')}>
                  {!customer && !stubName && t('sentences.reservedWithoutOwner')}
                  <PlayerName className={'block truncate'} slot={booking} />
                </p>
                <div className={'flex gap-2'}>
                  {isMember && (
                    <div className={cx(styles.memberBox)}>
                      <p>{t('words.member')}</p>
                    </div>
                  )}
                  {partnership && (
                    <div className={cx(styles.memberBox)}>
                      <p>{t('words.partner')}</p>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              {spaces?.map((space) => {
                return (
                  <React.Fragment key={space.id}>
                    <div className={cx(styles.row)}>
                      <p className={cx(styles.text)}>{space.name}</p>
                      <p className={cx(styles.text)}>{space.total}</p>
                    </div>
                    <hr />
                  </React.Fragment>
                )
              })}
              <div className={cx(styles.row)}>
                <p className={cx(styles.text, styles.black)}>{t('words.total')}</p>
                <p className={cx(styles.text, styles.black)}>{total}</p>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Portal>
  )
}

BookingPreviewBox.propTypes = {
  className: PropTypes.string,
  booking: PropTypes.object,
  visible: PropTypes.bool,
  venue: PropTypes.object,
  containerRef: PropTypes.any,
  onClosePreview: PropTypes.func,
}

BookingPreviewBox.defaultProps = {
  className: '',
  onClosePreview: () => {},
}

export default BookingPreviewBox
