import { GolfCourseItem } from '@sweetspot/club-portal-legacy/store/types'
import { Range } from '@sweetspot/shared/types'
import { UpdateRangePayload } from '@sweetspot/sweetspot-js/features/ranges/services'

export type ManageRangeContextValue = {
  areRangesLoading: boolean
  selectedRange?: Range
  setSelectedRange?: (range: Range) => void
  ranges: Range[]
  refetchRanges: () => void
  openCreateRangeSidebar: boolean
  toggleCreateRangeSideBar: () => void
  openDiscardDialog?: boolean
  setOpenDiscardDialog?: (open: boolean) => void
  discardDialogCallbacks?: DiscardDialogCallbacks
  setDiscardDialogCallbacks?: (callbacks: DiscardDialogCallbacks) => void
}

export enum RangeTabs {
  GENERAL = 'general',
  BOOKING = 'booking',
  PAYMENT = 'payment',
}

export type UpdateRangeData = Partial<
  UpdateRangePayload & { memberships?: string[]; imageId?: number }
>

export type FormatedTimeZone = {
  id: string
  name: string
  offset: number
  timezone_id: string
}

export type DiscardDialogCallbacks = {
  onClose: () => void
  onDiscard: () => void
  onSave: () => void
}

export type UploadImageType = {
  content_url?: string
  media_object?: {
    content_url?: string
  }
  url?: string
  id: number
}

export type CurrentCourse = Omit<GolfCourseItem, 'lonlat'> & {
  lonlat: {
    latitude?: number
    longitude?: number
  }
}
