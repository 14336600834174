import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import CategorySelect from '../components/CategorySelect'
import GolfIdSelect from '../components/GolfIdSelect/GolfIdSelect'
import PrimeTimeSelect from '../components/PrimeTimeSelect/PrimeTimeSelect'
import DateTimeRangeInput from '../components/DateTimeRangeInput'
import SlotsField from '../components/SlotsField'
import { useNewPeriod, usePeriodsList, useSelectedCourse } from '../context'
import style from '../style.module.scss'
import FormButtons from './NewPeriodComponents/FormButtons'
import IntervalField from './NewPeriodComponents/IntervalField'
import NameField from './NewPeriodComponents/NameField'
import getDefaultValues from './getDefaultValues'
import { validateDraft } from './validateForm'
import {
  AMPLITUDE_USER_PROPERTIES,
  AMPLITUDE_OPERATION_METHODS,
} from '@sweetspot/shared/util/constants'
import { updateUserProperty, getVenueType } from '@sweetspot/club-portal-legacy/utils/amplitude'

const NewPeriodPage = ({ location, history }) => {
  const { t } = useTranslation()
  const { selectedCourse } = useSelectedCourse()
  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })
  const { periodsList } = usePeriodsList()
  const [triedToSubmit, setTriedToSubmit] = useState(false)
  const { saveNewPeriod, newPeriod } = useNewPeriod()
  const { register, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: getDefaultValues(periodsList),
  })
  const formState = watch()

  const hasCdhNumber = currentClub?.cdh_id

  useEffect(() => {
    reset(getDefaultValues(periodsList))
    setTriedToSubmit(false)
  }, [periodsList, reset])

  useEffect(() => {
    if (newPeriod) {
      history.push(location.pathname.replace(/\/new$/, ''))
    }
  }, [periodsList, newPeriod, history, location.pathname])

  const onFormSubmit = (formState) => {
    if (!triedToSubmit) {
      setTriedToSubmit(true)
    }

    if (!selectedCourse) {
      return
    }

    const errors = validateDraft(formState, selectedCourse, t)
    if (errors) {
      return
    }
    updateUserProperty(
      AMPLITUDE_USER_PROPERTIES.VENUE_TYPE,
      getVenueType(selectedCourse.type),
      AMPLITUDE_OPERATION_METHODS.POST_INSERT
    )
    saveNewPeriod(formState)
  }

  const onCancel = () => {
    reset(getDefaultValues(periodsList))
    setTriedToSubmit(false)
  }

  const errors = validateDraft(formState, selectedCourse, t) || {}

  const createDisabled = !Object.keys(errors).every((key) =>
    ['start_date', 'end_date', 'start_time_from', 'start_time_to'].includes(key)
  )

  return (
    <div className={style.timePeriodsFormContainer}>
      <FormButtons
        onCancel={onCancel}
        onFormSubmit={handleSubmit(onFormSubmit)}
        createDisabled={createDisabled}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="new-period-form-container">
          <div className={style.teeTimePeriodOptions}>
            <NameField errors={errors} triedToSubmit={triedToSubmit} register={register} />
            <DateTimeRangeInput
              errors={errors}
              triedToSubmit={triedToSubmit}
              formState={formState}
              setValue={setValue}
              timeZone={selectedCourse?.timezone}
            />
            <IntervalField
              errors={errors}
              triedToSubmit={triedToSubmit}
              formState={formState}
              setValue={setValue}
            />
            <SlotsField
              errors={errors}
              triedToSubmit={triedToSubmit}
              formState={formState}
              setValue={setValue}
            />
            <CategorySelect formState={formState} setValue={setValue} />
            <GolfIdSelect formState={formState} setValue={setValue} hasCdhNumber={hasCdhNumber} />
            <PrimeTimeSelect formState={formState} setValue={setValue} />
          </div>
        </div>
      </form>
    </div>
  )
}

export default NewPeriodPage
