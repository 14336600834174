import React, { useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCheck, faUserClock } from '@fortawesome/pro-solid-svg-icons'

import styles from './styles.module.scss'
import { useQuery } from 'react-query'
import { CLUB_QUERIES } from '@sweetspot/shared/util/constants'
import {
  addClubUserRole,
  deleteClubUser,
  getClubUserInvites,
  queryClubUsers,
  removeClubUserRole,
  resendClubUserInvite,
} from '@sweetspot/sweetspot-js/features/clubUsers/services/api-platform'
import { useSelector } from 'react-redux'
import { mergeFirstLastName, to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { ROLES_ARR, ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import moment from 'moment'

import produce from 'immer'
import { useDebounce } from '@sweetspot/sweetspot-js/common/hooks/useDebounce'

import Grid from '@sweetspot/club-portal-legacy/components/Grid'
import useMergeState from '@sweetspot/sweetspot-js/common/hooks/useMergeState'
import PopoverRadioOptions from '@sweetspot/sweetspot-js/common/components/PopoverRadioOptions'
import PopoverMultiSelect from '@sweetspot/sweetspot-js/common/components/PopoverMultiSelect'
import {
  addAdminToCourse,
  removeAdminFromCourse,
} from '@sweetspot/sweetspot-js/features/courses/services/api-platform'
import EllipsedText from '@sweetspot/sweetspot-js/common/components/EllipsedText'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import {
  getFirstMatchingRole,
  hasAccess,
} from '@sweetspot/sweetspot-js/features/userAccess/utils/utils'
import { useToasts } from 'react-toast-notifications'
import { Route, Switch, useHistory, useLocation } from 'react-router'
import InviteClubUserModal from '@sweetspot/club-portal-legacy/modals/InviteClubUserModal'
import { ACCESS_KEYS } from '@sweetspot/sweetspot-js/features/userAccess/constants/accessTable'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import useMe from '@sweetspot/sweetspot-js/common/hooks/useMe'
import { queryClubCourses } from '@sweetspot/sweetspot-js/features/golfClubs/services/api-platform'
import { getAllPagesRequest } from '@sweetspot/sweetspot-js/common/functions/getAllPagesRequest'

const ROWS_PER_PAGE = 50

const ClubUsers = () => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  let history = useHistory()
  let location = useLocation()

  // Redux
  const currentClub = useSelector(({ golfClub }) =>
    golfClub?.list.find((club) => club?.id === golfClub?.selectedId)
  )
  const currentUserRoles = useRoles()
  const me = useMe()

  // Ref
  const currentItemRef = useRef()

  // Memos
  const translatedRoles = useMemo(() => {
    return ROLES_ARR.filter((r) => !r.hidden).map((r) => ({
      ...r,
      description: t(r.description),
      label: t(r.label),
    }))
  }, [t])

  const currentUserRole = useMemo(() => {
    return getFirstMatchingRole(currentUserRoles)?.value
  }, [currentUserRoles])

  const canEdit = useMemo(() => {
    return hasAccess(ACCESS_KEYS.SETTINGS.CLUB_USERS.EDIT, currentUserRole)
  }, [currentUserRole])

  const canInvite = useMemo(() => {
    return hasAccess(ACCESS_KEYS.SETTINGS.CLUB_USERS.INVITE, currentUserRole)
  }, [currentUserRole])

  // States
  const [currentItemUser, setCurrentItemUser] = useState()
  const [selectRoleVisible, setSelectRoleVisible] = useState(false)
  const [selectCoursesVisible, setSelectCoursesVisible] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 1000)
  const [isSearching, setIsSearching] = useState(false)
  const [clubUsers, setClubUsers] = useState([])
  const [clubUserInvites, setClubUserInvites] = useState([])

  const [pageData, setPageData] = useMergeState({
    totalRows: 0,
    currentPage: 1,
    totalPages: 1,
    loadedPages: [],
  })

  useEffect(() => {
    if (searchText && !debouncedSearchText) setIsSearching(true)
    else if (!searchText && debouncedSearchText) setIsSearching(true)
    else setIsSearching(false)
  }, [searchText, debouncedSearchText])

  const getCurrentClubRoleForUser = (rolesCollection) => {
    return rolesCollection.find((collection) => collection.club.id === currentClub.id).role.name
  }

  const formatData = (arr) => {
    if (!arr) return []
    return arr.map((item) => {
      const { id, first_name, last_name, roles_collection } = item
      return {
        ...item,
        _id: id,
        userName: mergeFirstLastName(first_name, last_name) || '-',
        currentClubRole: getCurrentClubRoleForUser(roles_collection),
      }
    })
  }

  const { data: currentClubCoursesList, isFetching: isFetchingCurrentClubCoursesList } = useQuery(
    [CLUB_QUERIES.COURSES, { club: currentClub?.id }],
    () => queryClubCourses(currentClub?.id, { page: 1, limit: 999 }),
    {
      enabled: !!currentClub?.id,
    }
  )
  const formatedCourses = useMemo(() => {
    if (!currentClubCoursesList) return []
    return currentClubCoursesList.map((c) => ({
      ...c,
      description: null,
      value: c.uuid,
      label: c.name,
    }))
  }, [currentClubCoursesList])

  const gridData = useMemo(() => {
    let allCourses = null
    let filteredClubUserInvites = null
    if (formatedCourses) {
      allCourses = formatedCourses.map((c) => ({ name: c.name, uuid: c.uuid }))
    }

    if (debouncedSearchText) {
      filteredClubUserInvites = clubUserInvites.filter(
        (clubUserInvitesObj) =>
          (clubUserInvites?.userName &&
            clubUserInvitesObj?.userName?.includes(debouncedSearchText)) ||
          clubUserInvitesObj?.email?.includes(debouncedSearchText)
      )

      return [...filteredClubUserInvites, ...clubUsers].map((item) => ({ ...item, allCourses }))
    }

    return [...clubUserInvites, ...clubUsers].map((item) => ({ ...item, allCourses }))
  }, [debouncedSearchText, clubUserInvites, clubUsers, formatedCourses])

  // Query
  const { isFetching: isFetchingClubUsers } = useQuery(
    [
      CLUB_QUERIES.CLUB_USERS,
      {
        page: pageData.currentPage,
        limit: ROWS_PER_PAGE,
        club: currentClub?.uuid,
        'roles[]': ROLES_ARR.filter((r) => !r.hidden).map((role) => role.value),
        search: debouncedSearchText || null,
      },
    ],
    () => {
      setClubUsers([])
      return queryClubUsers({
        page: pageData.currentPage,
        limit: ROWS_PER_PAGE,
        club: currentClub?.uuid,
        'roles[]': ROLES_ARR.filter((r) => !r.hidden).map((role) => role.value),
        search: debouncedSearchText || null,
      })
    },
    {
      enabled: !!currentClub?.uuid,
      onSuccess: (data) => {
        const totalRows = data?.length || 0
        const { currentPage, loadedPages } = pageData

        if (!loadedPages.includes(currentPage)) {
          if (totalRows >= ROWS_PER_PAGE) {
            setPageData((prev) => ({
              totalPages: prev.totalPages + 1,
            }))
          }
        }
        setPageData((prev) => ({ totalRows, loadedPages: [...prev.loadedPages, currentPage] }))
        setClubUsers(formatData(data))
      },
      onError: () => setClubUsers([]),
    }
  )

  const { isFetching: isFetchingClubUserInvites, refetch: refetchClubUserInvites } = useQuery(
    [CLUB_QUERIES.CLUB_USER_INVITES, currentClub?.id, location],
    () => getAllPagesRequest(getClubUserInvites, currentClub.id, { page: 1, limit: 50 }),
    {
      enabled: !!currentClub,
      onSuccess: (data) => {
        setClubUserInvites(
          data
            .filter((item) => ['not_active', 'expired'].includes(item.status))
            .map((item) => ({
              ...item,
              _id: `invite-${item.id}`,
              type: 'invite',
              ...(item?.club_user
                ? {
                    userName:
                      mergeFirstLastName(item?.club_user?.first_name, item?.club_user?.last_name) ||
                      '-',
                  }
                : {}),
            }))
        )
      },
      onError: () => setClubUserInvites([]),
    }
  )

  // Functions
  const getUserRole = (role) => {
    return ROLES_DEF?.[role] || null
  }
  const deleteUser = async () => {
    const { id } = currentItemUser

    setClubUsers(
      produce((draft) => {
        const index = draft.findIndex((u) => u.id === id)

        if (index !== -1) {
          draft.splice(index, 1)
        }
      })
    )

    setSelectRoleVisible(false)

    await deleteClubUser(id)
  }

  const shouldShowAllCourses = (role) => {
    return [
      ROLES_DEF.ROLE_CLUB_OWNER.value,
      ROLES_DEF.ROLE_ADMIN.value,
      ROLES_DEF.ROLE_CUSTOMER_SERVICE.value,
    ].includes(role)
  }

  const applyNewRoles = async (newValue) => {
    const { id: userId, currentClubRole } = currentItemUser

    setSelectRoleVisible(false)

    if (!newValue) return

    setClubUsers(
      produce((draft) => {
        const index = draft.findIndex((u) => u.id === userId)
        if (index !== -1) {
          draft[index].currentClubRole = newValue
        }
      })
    )

    try {
      await removeClubUserRole(userId, { role: currentClubRole, club: currentClub.uuid })

      await addClubUserRole(userId, { role: newValue, club: currentClub.uuid }).catch((err) => {
        addClubUserRole(userId, { role: currentClubRole, club: currentClub.uuid })
        throw err
      })
    } catch (error) {
      addToast(t('sentences.failedToSaveSettings'), { appearance: 'error' })
      setClubUsers(
        produce((draft) => {
          const index = draft.findIndex((u) => u.id === userId)
          if (index !== -1) {
            draft[index].currentClubRole = currentClubRole
          }
        })
      )
    }
  }

  const applyNewCourses = async (newValues) => {
    const { courses: currentCourses, uuid: userUuid } = currentItemUser

    const newCourses = formatedCourses.filter((c) => newValues.includes(c.uuid))
    const coursesToRemove = currentCourses.filter((c) => !newValues.includes(c.uuid))
    const coursesToAdd = newCourses.filter((c) => !currentCourses.find((cc) => cc.uuid === c.uuid))

    setSelectCoursesVisible(false)
    setClubUsers(
      produce((draft) => {
        const index = draft.findIndex((u) => u.uuid === userUuid)
        if (index !== -1) {
          draft[index].courses = newCourses
        }
      })
    )

    if (!coursesToRemove?.length && !coursesToAdd?.length) return

    try {
      for (const course of coursesToRemove) {
        await removeAdminFromCourse(course.id, { admin: userUuid })
      }
      for (const course of coursesToAdd) {
        await addAdminToCourse(course.id, { admin: userUuid })
      }
    } catch (error) {
      addToast(t('sentences.failedToSaveSettings'), { appearance: 'error' })
      setClubUsers(
        produce((draft) => {
          const index = draft.findIndex((u) => u.uuid === userUuid)
          if (index !== -1) {
            draft[index].courses = currentCourses
          }
        })
      )
    }
  }

  const handleActionConfirm = (action) => {
    const { action: actionName } = action
    if (actionName === 'inviteNewUser') {
      history.push('/settings/club-users/invite')
    }
  }

  const setInviteRowLoading = (value, uuid) => {
    setClubUserInvites(
      produce((draft) => {
        const index = draft.findIndex((invite) => invite.uuid === uuid)
        if (index !== -1) {
          draft[index].loading = value
        }
      })
    )
  }

  const resendInvite = async (invite) => {
    setInviteRowLoading(true, invite.uuid)
    const [res] = await to(
      resendClubUserInvite(invite.token || 'goV9Q7yD58tEVM80ArQ2iOyOGMknyXCylQLGv')
    )
    if (res) {
      addToast(t('sentences.inviteResent'), { appearance: 'success' })
    } else {
      addToast(t('sentences.failedToResendInvite'), { appearance: 'error' })
    }
    refetchClubUserInvites()
  }

  return (
    <div className={cx(styles.container)}>
      <Grid
        actions={
          canInvite
            ? [
                {
                  id: 1,
                  label: 'sentences.inviteNewUser',
                  action: 'inviteNewUser',
                },
              ]
            : []
        }
        actionsEnabled={canInvite}
        pageTitle={t('sentences.clubUsers')}
        searchPlaceholder={t('sentences.searchByNameOrEmail')}
        isLoading={
          isFetchingClubUserInvites ||
          isFetchingClubUsers ||
          isSearching ||
          isFetchingCurrentClubCoursesList
        }
        headers={{
          userName: {
            name: '.sentences.userName',
            type: 'label',
            valueType: 'string',
            selected: true,
            width: '176px',
          },
          email: {
            name: '.words.email',
            type: 'label',
            valueType: 'string',
            selected: true,
            width: '237px',
            display: (value) => value || '-',
          },
          currentClubRole: {
            name: '.words.role',
            selected: true,
            render: (value, headerItem, row = {}) => {
              if (row?.type === 'invite') {
                const { roles } = row.configuration
                const role = getFirstMatchingRole(roles)
                return (
                  <div>
                    <p className={cx(styles.customCellText)}>{t(role.label)}</p>
                  </div>
                )
              }

              const role = getUserRole(value)
              const isMe = me.id === row.id
              const text = role ? t(role.label) : '-'
              return (
                <div
                  className={cx(canEdit && !isMe && styles.customCell)}
                  onClick={(e) => {
                    if (!canEdit || isMe) return
                    currentItemRef.current = e.target
                    setCurrentItemUser({ ...row })
                    if (!selectRoleVisible) setSelectRoleVisible(true)
                  }}
                >
                  <p className={cx(styles.customCellText)}>{text}</p>
                </div>
              )
            },
            width: '140px',
          },
          courses: {
            name: '.words.course_plural',
            selected: true,
            render: (value, headerItem, row = {}) => {
              const { allCourses } = row

              if (row?.type === 'invite') {
                const { courses } = row.configuration
                return (
                  <div className={cx(styles.showOnHover)}>
                    <EllipsedText
                      id="courses"
                      className={cx(styles.customCellText)}
                      text={
                        allCourses
                          ? courses?.length === allCourses?.length
                            ? t('sentences.allCourses')
                            : courses
                                .map((c) => allCourses?.find((c2) => c2.uuid === c)?.name)
                                .join(', ')
                          : '-'
                      }
                    />
                  </div>
                )
              }
              const shouldShowAll = shouldShowAllCourses(row.currentClubRole)
              const isMe = me?.id === row.id
              return (
                <div
                  className={cx(
                    {
                      [styles.customCell]: canEdit && !shouldShowAll && !isMe,
                    },
                    styles.showOnHover
                  )}
                  onClick={(e) => {
                    if (!canEdit || shouldShowAll || isMe) return
                    currentItemRef.current = e.target
                    setCurrentItemUser({ ...row })
                    if (!selectCoursesVisible) setSelectCoursesVisible(true)
                  }}
                >
                  <EllipsedText
                    id="courses"
                    className={cx(styles.customCellText)}
                    text={
                      shouldShowAll
                        ? t('sentences.allCourses')
                        : value?.length
                        ? value.length === allCourses?.length
                          ? t('sentences.allCourses')
                          : value.map((c) => c.name).join(', ')
                        : '-'
                    }
                  />
                </div>
              )
            },
            width: '188px',
          },
          lastLogin: {
            name: '.sentences.lastLogin',
            valueType: 'string',
            type: 'label',
            selected: true,
            display: (value, row) =>
              row?.last_login ? moment.utc(row.last_login).local().format('YYYY-MM-DD HH:mm') : '-',
            width: '149px',
          },
          status: {
            width: '110px',
            name: '.words.status',
            selected: true,
            render: (value, headerItem, row) => {
              if (row?.loading) {
                return (
                  <div>
                    <PulseLoader showIf={true} dotStyles={{ height: 10, width: 10 }} />
                  </div>
                )
              }
              if (row?.type === 'invite') {
                const { status } = row
                if (status === 'not_active') {
                  return (
                    <div className="text-text-medium text-content-xs flex items-center gap-1">
                      <FontAwesomeIcon className="fa-xs" icon={faUserClock} />
                      <span className="mt-px">{t('words.inActive')}</span>
                    </div>
                  )
                } else if (status === 'expired') {
                  return (
                    <div className={cx(styles.customCell)} onClick={() => resendInvite(row)}>
                      <p className={cx(styles.customCellText)}>
                        {t('communication.resendInvitation')}
                      </p>
                    </div>
                  )
                }
              }
              return (
                <div className="text-text-success text-content-xs flex items-center gap-1">
                  <FontAwesomeIcon className="fa-xs" icon={faUserCheck} />
                  <span className="mt-px">{t('words.active')}</span>
                </div>
              )
            },
          },
        }}
        pagination
        searchEnabled
        hideArrows
        totalPages={pageData.totalPages}
        rowsPerPage={ROWS_PER_PAGE}
        totalRows={pageData.totalRows}
        values={gridData}
        onSearchChange={(val) => setSearchText(val)}
        onSearch={() => setSearchText((prev) => prev)}
        hideSearchButton
        setCurrentPage={(page) => setPageData({ currentPage: page })}
        onActionConfirm={handleActionConfirm}
      />
      <PopoverRadioOptions
        options={translatedRoles}
        selectedValue={getUserRole(currentItemUser?.currentClubRole)?.value || null}
        isVisible={selectRoleVisible}
        popoverOptions={{
          anchorElementRef: currentItemRef,
        }}
        destructiveAction={{
          label: t('sentences.deleteUser'),
          onClick: () => deleteUser(),
        }}
        cancelAction={{
          label: t('words.cancel'),
          onClick: () => setSelectRoleVisible(false),
        }}
        applyAction={{
          label: t('words.apply'),
          onClick: (newValue) => applyNewRoles(newValue),
        }}
      />
      <PopoverMultiSelect
        options={formatedCourses}
        selectedValues={currentItemUser?.courses?.map((c) => c.uuid) || []}
        isVisible={selectCoursesVisible}
        popoverOptions={{
          anchorElementRef: currentItemRef,
        }}
        enableSelectAll={true}
        cancelAction={{
          label: t('words.cancel'),
          onClick: () => setSelectCoursesVisible(false),
        }}
        applyAction={{
          label: t('words.apply'),
          onClick: (newValues) => applyNewCourses(newValues),
        }}
      />
      <Switch>
        <Route exact path="/settings/club-users/invite" component={InviteClubUserModal} />
      </Switch>
    </div>
  )
}

export default ClubUsers
