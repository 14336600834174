import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import cx from 'classnames'

import InfoHover from '@sweetspot/sweetspot-js/common/components/InfoHover'
import TextInputField from '@sweetspot/sweetspot-js/common/components/FormElements/TextInputField'
import ToggleSwitchControlled from '@sweetspot/sweetspot-js/common/components/ToggleSwitchControlled'
import ToggleButton from '@sweetspot/sweetspot-js/common/components/FormElements/ToggleButton'
import styles from './styles.module.scss'

// Utils
import GetTranslatedString from '@sweetspot/club-portal-legacy/helpers/GetTranslatedString'
import InputSelectDateSimple from '@sweetspot/sweetspot-js/ui-kit/components/InputSelectDateSimple'
import moment from 'moment-timezone'
import Label from '@sweetspot/sweetspot-js/common/components/FormElements/Partials/Label'
import { useTranslation } from 'react-i18next'
import { toggleGitClubSelectable } from '@sweetspot/shared/data-access/api-platform'
import { useMutation } from 'react-query'
import useRoles from '@sweetspot/sweetspot-js/common/hooks/useRoles'
import MigrateButton from '@sweetspot/club-portal-legacy/components/Wizard/containers/membership/GeneralSettings/MigrateButton'
import {
  AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS,
  AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS,
} from '@sweetspot/club-portal-legacy/modals/MembershipWizardModal/validation'
import { FlagNames, useFlag } from '@sweetspot/shared/util/feature-flag'

const DESCRIPTION_MAX_LENGTH = 200

/**
 * Controlled wrapper
 */
const GeneralSettings = ({
  currentMembership,
  lang,
  values,
  errors,
  onSetMembershipValue,
  disabled,
  onlyFields,
  loading,
  handleOnSave,
  migrateMembershipAutorenewalSettings,
}) => {
  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })
  const isGitClubSelectEnabled = useFlag(FlagNames.GitClubSelect)
  const hasCdhNumber = !!currentClub?.cdh_id

  const TRANSLATIONS = {
    nameOfMembership: GetTranslatedString(lang, 'membership.options.nameOfMembership'),
    name: GetTranslatedString(lang, 'wizard.membershipName'),
    hiddenInApp: GetTranslatedString(lang, 'membership.settings.hiddenInApp'),
    duration: GetTranslatedString(lang, 'wizard.duration'),
    annual: GetTranslatedString(lang, 'membership.options.calendarYear'),
    twelve_months: GetTranslatedString(lang, 'membership.options.twelveMonths'),
    gitSync: GetTranslatedString(lang, hasCdhNumber ? 'wizard.cdhSync' : 'wizard.gitSync'),
    active: GetTranslatedString(lang, 'settings.active'),
    inactive: GetTranslatedString(lang, 'settings.inactive'),
    membershipStatus: GetTranslatedString(lang, 'wizard.membershipStatus'),
    enableSSPayment: GetTranslatedString(lang, 'wizard.enableSSPayment'),
    enabled: GetTranslatedString(lang, 'enabled'),
    disabled: GetTranslatedString(lang, 'disabled'),
    required: GetTranslatedString(lang, 'required'),
    playRight: GetTranslatedString(lang, 'playRight'),
    create: GetTranslatedString(lang, 'create'),
    save: GetTranslatedString(lang, 'save'),
    payment: GetTranslatedString(lang, 'payment'),
    description: GetTranslatedString(lang, 'settings.description'),
    period: GetTranslatedString(lang, 'membership.options.period'),
    startDate: GetTranslatedString(lang, 'membership.options.startDate'),
    endDate: GetTranslatedString(lang, 'membership.options.endDate'),
    signupDate: GetTranslatedString(lang, 'membership.options.signupDate'),
    durationApplied: GetTranslatedString(lang, 'membership.options.durationApplied'),
    noEnd: GetTranslatedString(lang, 'membership.options.noEnd'),
    chooseDate: GetTranslatedString(lang, 'membership.options.chooseDate'),
  }

  const [showActionButtons, setShowActionButtons] = useState(false)
  const [isNoEnd, setIsNoEnd] = useState()
  const [descriptionText, setDescriptionText] = useState(values.description || '')
  const { t } = useTranslation()
  const user = useRoles()
  const [enableGitCLubSelect, setEnableGitCLubSelect] = useState(values.is_git_club_selectable)
  const toggleGitClubSelect = useMutation({
    mutationFn: () => {
      setEnableGitCLubSelect(!enableGitCLubSelect)
      return toggleGitClubSelectable(values.id)
    },
    onSuccess: (data) => setEnableGitCLubSelect(data.is_git_club_selectable),
    onError: () => setEnableGitCLubSelect(!enableGitCLubSelect),
  })

  const setDurationValue = (key, value) => {
    let { options: durationOptions } = values.duration

    if (value === true && !durationOptions.includes(key)) {
      onSetMembershipValue('duration', {
        ...values.duration,
        options: [...durationOptions, key],
        from:
          key === 'period'
            ? values.duration.from ?? moment.utc().startOf('day').format()
            : values.duration.from,
      })
    } else if (value === false) {
      onSetMembershipValue('duration', {
        ...values.duration,
        options: durationOptions.filter((item) => item !== key),
        from: key === 'period' ? null : values.duration.from,
        to: key === 'period' ? null : values.duration.to,
      })
    }
  }

  useEffect(() => {
    setIsNoEnd(values.duration.to === null)
  }, [values?.duration?.to])

  useEffect(() => {
    setDescriptionText(values?.description || '')
  }, [values?.description])

  const getError = (field) => {
    return errors && errors[field] ? errors[field] : ''
  }

  const handleNoEnd = (value) => {
    setIsNoEnd(value)
    handleDateChange('to', value ? null : values.duration.to)
  }

  const handleDescriptionChange = (value) => {
    if (value.length > DESCRIPTION_MAX_LENGTH) {
      value = value.substring(0, DESCRIPTION_MAX_LENGTH)
    }
    setDescriptionText(value)
    onSetMembershipValue('description', value)
  }
  const handleAutoRenewalDaysChange = (key, value, minAndMaxValues) => {
    onSetMembershipValue('autorenewal', {
      ...values.autorenewal,
      [key]: value,
    })
  }

  const toggleAutoRenewal = (value) => {
    const updatedValues = {
      ...values.autorenewal,
      is_active: value,
    }

    // In case of the initial toggle on, we need to be sure that
    // the days_before_cancellation and days_before_email have values.
    if (value) {
      // If days_before_cancellation does not have a value, set it to the minimum value.
      if (!updatedValues.days_before_cancellation) {
        updatedValues.days_before_cancellation = AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS.min
      }

      // If days_before_email does not have a value, set it to the minimum value.
      if (!updatedValues.days_before_email) {
        updatedValues.days_before_email = AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS.min
      }
    }
    onSetMembershipValue('autorenewal', updatedValues)
  }

  const handleCancel = () => {
    if (!showActionButtons) return
    setShowActionButtons(false)
    onSetMembershipValue('name', currentMembership?.name || '')
  }

  const handleDateChange = (key, date) => {
    if (date) {
      date = moment(date).utc().startOf('day').format()
    }
    onSetMembershipValue('duration', {
      ...values.duration,
      [key]: date?.toString() || null,
    })
  }

  const handleRequirementUpdate = (key, value) => {
    const reqs = [...values.membership_requirements]
    const index = reqs.findIndex((obj) => obj.type === key)
    if (index > -1) {
      reqs[index] = {
        ...reqs[index],
        configuration: { ...reqs[index].configuration, setting: value },
      }
    } else {
      reqs.push({ type: key, configuration: { setting: value } })
    }
    onSetMembershipValue('membership_requirements', reqs)
  }

  const renderFields = () => {
    return (
      <React.Fragment>
        <TextInputField
          containerWidth={'half'}
          label={TRANSLATIONS.nameOfMembership}
          lightMainLabelColor
          placeholder={TRANSLATIONS.name}
          value={values.name}
          error={getError('name')}
          onChange={(val) => onSetMembershipValue('name', val)}
          type="text"
          onInputFocus={() => setShowActionButtons(true)}
          inputProps={{ autoFocus: !loading && !currentMembership?.name }}
          showActionButtons={!values.name || showActionButtons}
          showConfirmButton
          confirmText={currentMembership?.name ? TRANSLATIONS.save : TRANSLATIONS.create}
          showCancelButton={!!values.name}
          onConfirm={() => {
            setShowActionButtons(false)
            handleOnSave()
          }}
          onCancel={() => handleCancel()}
          onOutsideClick={showActionButtons ? () => handleCancel() : null}
          confirmLoading={loading}
        />

        <div className={styles.descriptionLabelRow}>
          <Label label={TRANSLATIONS.description} lightColor></Label>
          <Label label={`${descriptionText.length}/${DESCRIPTION_MAX_LENGTH}`} lightColor></Label>
        </div>
        <TextInputField
          containerWidth={'85%'}
          rows={4}
          lightMainLabelColor
          value={descriptionText}
          // error={getError('name')}
          onChange={(value) => handleDescriptionChange(value)}
          onInputBlur={() => onSetMembershipValue('description', descriptionText)}
          type="text-area"
          disabled={disabled}
        />

        <ToggleButton
          value={values.play_right_only}
          label={TRANSLATIONS.playRight}
          secondaryLabel={TRANSLATIONS.required}
          error={getError('play_right_only')}
          onChange={(val) => onSetMembershipValue('play_right_only', val)}
          disabled={disabled}
        />
        <ToggleButton
          value={values.duration.options.includes('annual_duration')}
          label={TRANSLATIONS.duration}
          secondaryLabel={TRANSLATIONS.annual}
          error={getError('duration')}
          onChange={(newValue) => setDurationValue('annual_duration', newValue)}
          disabled={disabled}
        />
        <ToggleButton
          value={values.duration.options.includes('12_month')}
          secondaryLabel={TRANSLATIONS.twelve_months}
          error={getError('duration')}
          onChange={(newValue) => setDurationValue('12_month', newValue)}
          disabled={disabled}
          lightLabel
        />
        <ToggleButton
          value={values.duration.options.includes('period')}
          secondaryLabel={TRANSLATIONS.period}
          error={getError('duration')}
          onChange={(newValue) => setDurationValue('period', newValue)}
          disabled={disabled || values.autorenewal?.is_active}
        />
        {values.duration.options.includes('period') && (
          <div className={cx(styles.periodContainer)}>
            <div>
              <Label label={TRANSLATIONS.startDate} lightColor />
            </div>
            <div>
              <InputSelectDateSimple
                className={cx(styles.dateInput)}
                currentDate={moment(values.duration.from) ?? moment.utc()}
                onSetDate={(value) => handleDateChange('from', value)}
                disableBeforeToday={true}
                orientation={'right'}
                startDate={moment().utc()}
                endDate={
                  values.duration.to
                    ? moment(values.duration.to).subtract(1, 'day')
                    : moment().add(10, 'years')
                }
                value={
                  values.duration.from !== null
                    ? moment(values.duration.from).format('YYYY-MM-DD')
                    : moment.utc().startOf('day').format('YYYY-MM-DD')
                }
              />
            </div>
            <div>
              <Label label={TRANSLATIONS.endDate} lightColor />
            </div>
            <div>
              <InputSelectDateSimple
                className={cx(styles.dateInput)}
                currentDate={
                  values.duration.to !== null
                    ? moment(values.duration.to)
                    : values.duration.from !== null
                    ? moment(values.duration.from)
                    : moment()
                }
                onSetDate={(value) => handleDateChange('to', value)}
                disableBeforeToday={true}
                placeholder={isNoEnd ? TRANSLATIONS.noEnd : TRANSLATIONS.chooseDate}
                orientation={'right'}
                startDate={moment(values.duration.from).add(1, 'day')}
                endDate={moment().add(10, 'years')}
                value={
                  values.duration.to !== null ? moment(values.duration.to).format('YYYY-MM-DD') : ''
                }
                disabled={isNoEnd}
              />
            </div>
            {/* <div className={}> */}
            <ToggleButton
              value={isNoEnd}
              secondaryLabel={TRANSLATIONS.noEnd}
              error={getError('noend')}
              containerWidth="quarter"
              onChange={(value) => handleNoEnd(value)}
              containerClassName={cx(styles.toggleEndDate)}
            />
            {/* </div> */}
          </div>
        )}

        <ToggleButton
          value={!!values.is_git_sync}
          label={TRANSLATIONS.gitSync}
          secondaryLabel={TRANSLATIONS.required}
          error={getError('play_right_only')}
          onChange={(newValue) => onSetMembershipValue('is_git_sync', newValue)}
          disabled={disabled}
          lightLabel
        />
        {/* Commented out barring investigation into why this is not working as intended

        <ToggleButton
          value={values.channels && values.channels.includes('web') ? true : false}
          label={TRANSLATIONS.payment}
          secondaryLabel={TRANSLATIONS.enableSSPayment}
          error={getError('is_payment_through_ss')}
          onChange={(newValue) =>
            onSetMembershipValue(
              'channels',
              newValue
                ? values.channels?.concat('web') || ['web']
                : values.channels?.filter((channel) => channel !== 'web') || []
            )
          }
          disabled={disabled}
        /> */}
        <ToggleButton
          value={!!values.is_sell_membership_enabled}
          secondaryLabel={t('memberships.label_SellThroughSweetspot')}
          error={getError('is_sell_membership_enabled')}
          onChange={(newValue) => onSetMembershipValue('is_sell_membership_enabled', newValue)}
          disabled={disabled}
          lightLabel
        />
        {!!currentClub.git_id && (
          <>
            <ToggleButton
              value={
                !!values.membership_requirements?.find((req) => req.type === 'golf_id')
                  ?.configuration?.setting
              }
              secondaryLabel={t('memberships.label_GolfIdRequired')}
              error={getError('membership_requirements')}
              onChange={(newValue) => handleRequirementUpdate('golf_id', newValue)}
              disabled={disabled}
              lightLabel
            />
            {isGitClubSelectEnabled && (
              <ToggleButton
                value={enableGitCLubSelect ?? values.is_git_club_selectable}
                secondaryLabel={t('memberships.label_GitClubInfo')}
                error={getError('membership_git_club')}
                onChange={() => toggleGitClubSelect.mutate()}
                disabled={disabled}
                lightLabel
              />
            )}
          </>
        )}
        <ToggleButton
          value={values.autorenewal?.is_active}
          secondaryLabel={t('memberships.label_auto_renew_subscription')}
          error={getError('autorenewal')}
          onChange={(newValue) => toggleAutoRenewal(newValue)}
          disabled={disabled || values.duration.options.includes('period')}
          lightLabel
        />
        {values.autorenewal?.is_active && (
          <div className={cx(styles.membershipFieldsContainer)}>
            <div className={cx(styles.membershipField)}>
              <Label label={t('memberships.label_last_cancellation_date')} lightColor />
              <div className={cx(styles.membershipInputWithSuffix)}>
                <TextInputField
                  lightMainLabelColor
                  value={values.autorenewal?.days_before_cancellation}
                  error={getError('autorenewal')}
                  onChange={(newValue) =>
                    handleAutoRenewalDaysChange(
                      'days_before_cancellation',
                      newValue,
                      AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS
                    )
                  }
                  type="number"
                  disabled={disabled}
                  noMargin
                  inputProps={AUTO_RENEWAL_LAST_CANCELLATION_MIN_MAX_DAYS}
                />
                <Label label={t('memberships.label_last_cancellation_date_suffix')} />
              </div>
            </div>
            <div className={cx(styles.membershipField)}>
              <div className={cx(styles.emailToMemberLabelContainer)}>
                <Label label={t('memberships.label_email_to_member')} lightColor />
                <InfoHover
                  text={t('memberships.email_to_member_tooltip')}
                  containerClassName={cx(styles.emailToMemberTooltipContainer)}
                  arrowPosition={5}
                />
              </div>
              <div className={cx(styles.membershipInputWithSuffix)}>
                <TextInputField
                  lightMainLabelColor
                  value={values.autorenewal?.days_before_email}
                  error={getError('autorenewal')}
                  onChange={(newValue) =>
                    handleAutoRenewalDaysChange(
                      'days_before_email',
                      newValue,
                      AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS
                    )
                  }
                  type="number"
                  disabled={disabled}
                  noMargin
                  inputProps={AUTO_RENEWAL_EMAIL_TO_MEMBER_MIN_MAX_DAYS}
                />
                <Label label={t('memberships.label_email_to_member_suffix')} />
              </div>
            </div>
          </div>
        )}
        <MigrateButton
          autorenewal={values.autorenewal}
          membershipId={values.id}
          migrateMembershipAutorenewalSettings={migrateMembershipAutorenewalSettings}
        />
        <div className={cx(styles.toggleWrapper, 'mb-1')}>
          <ToggleSwitchControlled
            switchWidth="full"
            label={TRANSLATIONS.membershipStatus}
            error={getError('is_active')}
            checked={!!values.is_active}
            onChange={(newValue) => onSetMembershipValue('is_active', newValue)}
            textChecked={TRANSLATIONS.active}
            textUnchecked={TRANSLATIONS.inactive}
            disabled={disabled}
            medium
          />
        </div>
        {values?.is_sell_membership_enabled ? (
          <div className="flex w-full items-center justify-center pb-6">
            <p className="max-w-xl text-center text-xs text-red-400">
              {t('memberships.message_sell_membership_activate_information_first')}
              <br />
              {t('memberships.message_sell_membership_activate_information_second')}
              <br />
              {t('memberships.message_sell_membership_activate_information_third')}{' '}
              <a href="mailto:hello@sweetspot.io">
                <u className="text-red-400">
                  {t('memberships.message_sell_membership_activate_information_fourth')}
                </u>
              </a>
            </p>
          </div>
        ) : null}
      </React.Fragment>
    )
  }

  if (onlyFields === true) {
    return renderFields()
  }

  return <div className={cx(styles.container)}>{renderFields()}</div>
}

GeneralSettings.propTypes = {
  /** From Redux State */
  lang: PropTypes.string,
  /** From Parent */
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  onSetMembershipValue: PropTypes.func,
  handleOnSave: PropTypes.func,
  migrateMembershipAutorenewalSettings: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onlyFields: PropTypes.bool,
  currentMembership: PropTypes.object,
}

GeneralSettings.defaulProps = {
  values: {},
  currentMembership: {},
  errors: {},
  onSetMembershipValue: () => {},
  migrateMembershipAutorenewalSettings: () => {},
  disabled: false,
  loading: false,
  onlyFields: false,
}

const mapStateToProps = (state) => ({
  lang: state.auth.me.lang,
})

export default connect(mapStateToProps)(GeneralSettings)
