import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'
import { UpdateDemandModelPayload, DemandModel, CreateDemandModelPayload } from './types'

export const queryDemandModel = (rangeId?: string): Promise<DemandModel> => {
  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/demand-model`
  return request(URL) as Promise<DemandModel>
}

export const createDemandModel = (payload: CreateDemandModelPayload): Promise<DemandModel> => {
  const { rangeId, demand_entries } = payload
  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/demand-models`
  return request(URL, {
    method: 'POST',
    contentType: 'application/json',
    body: { demand_entries },
  })
}

export function updateDemandModel(payload: UpdateDemandModelPayload): Promise<DemandModel> {
  const { rangeId, demandModel } = payload
  const { demand_entries } = demandModel

  const URL = `${getBaseUrlPlatform()}/ranges/${rangeId}/demand-model`
  return request(URL, {
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: { demand_entries },
  })
}
