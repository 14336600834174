import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyState, EmptyStateTitle } from '@sweetspot/scramble-ds'

import { PricesTabs } from './PricesTabs'
import { useBallPricingContext } from '../../BallPricingProvider'

const PricesTab = () => {
  const { t } = useTranslation()
  const {
    bucketConfiguration: { bucketSizes, getBucketConfiguration, isFetching, isFetched },
  } = useBallPricingContext()

  useEffect(() => {
    getBucketConfiguration?.()
  }, [getBucketConfiguration])

  const isLoading = useMemo(() => !isFetched && isFetching, [isFetching, isFetched])

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <EmptyState
          iconName="fa-light fa-flip-both fa-loader bg-background-mono-lighter mb-4 flex rounded-full p-4"
          loading
        >
          <EmptyStateTitle>{t('words.loading')}</EmptyStateTitle>
        </EmptyState>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-1 flex-col gap-4">
      {bucketSizes.map((bucketSize) => (
        <div>
          <div className="px-md py-sm bg-background-mono-darker text-text-light">
            {bucketSize.nrOfBalls} {t('words.balls')}
          </div>
          <PricesTabs />
        </div>
      ))}
    </div>
  )
}

export default PricesTab
