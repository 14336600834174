/* eslint-disable @nx/enforce-module-boundaries */
import { useEffect, useCallback, useState, Suspense } from 'react'
import i18n from 'i18next'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { Router } from 'react-router-dom'
import { history } from '../../store'
import Routes from '../../pages'
import style from './style.module.scss'

import cx from 'classnames'

import { ToastProvider } from 'react-toast-notifications'

import CustomToast from '@sweetspot/club-portal-legacy/components/CustomToast'

import Header from '../Header'
import SideNav from '../SideNav'
import ReactQueryWrapper from './react-query-wrapper'
import PulseLoader from '@sweetspot/sweetspot-js/common/components/PulseLoader'
import {
  trackClubPropertiesToAmplitude,
  updateUserProperty,
} from '@sweetspot/club-portal-legacy/utils/amplitude'
import { ROLES_DEF } from '@sweetspot/sweetspot-js/features/userAccess/constants/roles'
import { AMPLITUDE_USER_PROPERTIES } from '@sweetspot/shared/util/constants'
import packageJson from '../../../../../package.json'
import { to } from '@sweetspot/sweetspot-js/common/functions/utils'
import { generateHubspotToken } from '@sweetspot/shared/data-access/api-platform'
import LogRocket from 'logrocket'

const appVersion = packageJson?.version || '0.0.0'

function Root() {
  const currentUser = useSelector((state) => state.auth)
  const currentClubId = useSelector((state) => state.golfClub.selectedId)
  const clubsList = useSelector((state) => state.golfClub.list)

  const shouldShowElements = () => {
    const path = history.location.pathname.toLowerCase()
    return (
      path !== '/login' && !path.includes('tee-sheet-links') && !path.includes('/club-user-invite/')
    )
  }

  const [showElements, setShowElements] = useState(shouldShowElements())
  const [updateSideMenu, setUpdateSideMenu] = useState(false)

  const getIdentificationToken = async () => {
    try {
      const [res] = await to(generateHubspotToken())
      return res?.token || null
    } catch (error) {
      console.error('Error fetching identification token:', error)
      return null
    }
  }

  useEffect(() => {
    const loadHubSpotChat = async () => {
      if (currentUser?.loggedIn && !currentUser?.isSuperAdmin) {
        const existingScript = document.getElementById('hs-script-loader')
        if (!existingScript) {
          const token = await getIdentificationToken()
          if (token) {
            window.hsConversationsSettings = {
              identificationEmail: currentUser?.me?.email,
              identificationToken: token,
            }

            const script = document.createElement('script')
            script.id = 'hs-script-loader'
            script.type = 'text/javascript'
            script.async = true
            script.defer = true
            script.src = '//js-eu1.hs-scripts.com/144245356.js'
            document.body.appendChild(script)

            script.onload = () => {
              if (window.HubSpotConversations) {
                window.HubSpotConversations.widget.load()
              } else {
                window.hsConversationsOnReady = [() => window.HubSpotConversations.widget.load()]
              }
            }
          }
        } else {
          if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.load()
          }
        }
      }
    }

    loadHubSpotChat()
  }, [currentUser])

  useEffect(() => {
    updateUserProperty(AMPLITUDE_USER_PROPERTIES.RELEASE_VERSION, appVersion)
    updateUserProperty(
      AMPLITUDE_USER_PROPERTIES.SCREEN_SIZE,
      `${window.screen.width}x${window.screen.height}`
    )

    if (currentUser?.loggedIn) {
      const currentClub = clubsList?.find((c) => c.id === currentClubId)
      trackClubPropertiesToAmplitude(currentClub)

      updateUserProperty(
        AMPLITUDE_USER_PROPERTIES.USER_ROLE,
        i18n.t(ROLES_DEF[currentUser.roles[0]].label, { lng: 'en' })
      )
    }
  }, [])

  useEffect(() => {
    if (currentUser?.loggedIn && currentUser?.me?.uuid) {
      const {
        uuid,
        email = 'EmailNotDefined',
        firstName = 'FirstNameNotDefined',
        lastName = 'LastNameNotDefined',
      } = currentUser.me
      LogRocket.identify(uuid, {
        email,
        name: `${firstName} ${lastName}`,
      })
    }
  }, [currentUser])

  useEffect(() => {
    setShowElements(shouldShowElements())
  }, [])

  const updateElements = useCallback(() => {
    setShowElements(shouldShowElements())
    setUpdateSideMenu((prevState) => !prevState)
  }, [])

  const updateMenu = useCallback(() => {
    setUpdateSideMenu((prevState) => !prevState)
  }, [])

  let _style = { content: {} }
  if (!showElements) _style.content.top = 0

  return (
    <Suspense
      fallback={
        <div className="fixed flex h-screen w-screen items-center justify-center">
          <PulseLoader showIf={true} />
        </div>
      }
    >
      <ReactQueryWrapper>
        <ToastProvider
          placement="bottom-center"
          autoDismissTimeout={4000}
          autoDismiss={true}
          components={{ Toast: CustomToast }}
        >
          <Router history={history}>
            <div className={style.root}>
              {showElements && <Header updateMenu={updateMenu} />}

              <div className={style.content} style={_style.content}>
                {showElements && <SideNav history={history} shouldUpdate={updateSideMenu} />}
                <div className={cx(style.page, 'main-page-wrapper')}>
                  <Routes history={history} onUpdate={updateElements} />
                </div>
              </div>
            </div>
          </Router>
        </ToastProvider>
      </ReactQueryWrapper>
    </Suspense>
  )
}

Root.propTypes = {
  dispatch: PropTypes.func,
}

export default connect()(Root)
