import { useSelector } from 'react-redux'
import DropdownSelect from '@sweetspot/club-portal-legacy/components/DropdownSelect'
import { formatToDatePicker, formatToTimePicker } from '@sweetspot/club-portal-legacy/utils/date'
import { CLUB_QUERIES, QUERY_KEYS } from '@sweetspot/shared/util/constants'
import { getSpaces } from '@sweetspot/sweetspot-js/features/spaces/services/api-platform'
import { useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import style from '../../style.module.scss'
import CategorySelect from '../CategorySelect'
import DateTimeRangeInput from '../DateTimeRangeInput'
import SlotsField from '../SlotsField'
import GolfIdSelect from '../GolfIdSelect/GolfIdSelect'
import PrimeTimeSelect from '../PrimeTimeSelect/PrimeTimeSelect'
import DayInput from './DayInput'
import ScheduledDateInput from './ScheduledDateInput'
import ScheduledTimeInput from './ScheduledTimeInput'
import { queryRanges } from '@sweetspot/sweetspot-js/features/ranges/services'
import { queryBays } from '@sweetspot/sweetspot-js/features/bays/services'

const AddOverrideForm = ({ period, addOverride, daysOptions, currentCourse }) => {
  const { t } = useTranslation()
  const [errors] = useState({})
  const currentClub = useSelector((state) => {
    return state?.golfClub?.list.find((club) => club.id === state?.golfClub?.selectedId)
  })

  const hasCdhNumber = currentClub?.cdh_id

  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: getInitialState(period),
  })
  const formState = watch()
  const { clubUuid, clubId } = useSelector((state) => {
    const currentClub = state?.golfClub?.list?.find?.(
      (club) => club.id === state?.golfClub?.selectedId
    )
    return {
      clubUuid: currentClub?.uuid,
      clubId: currentClub?.id,
    }
  })

  const onFormSubmit = handleSubmit((formState) => {
    addOverride(formState)
  })

  const toggleScheduleTo = (event) => {
    setValue('isScheduled', event.target.checked)
  }

  const { data: currentBayRange } = useQuery(
    [QUERY_KEYS.RANGES, clubId, currentCourse?.id],
    async () => {
      return queryRanges(clubUuid)
    },
    {
      enabled: !!clubUuid && !!currentCourse?.belongs_to_range_context,
      select: (data) => {
        return data?.ranges?.find((r) => r?.external_reference === currentCourse?.uuid)
      },
    }
  )

  const isNewRange = useMemo(() => {
    return currentCourse?.belongs_to_range_context && !!currentBayRange
  }, [currentCourse?.belongs_to_range_context, currentBayRange])

  const { data: spacesOptions, isFetching } = useQuery({
    queryKey: [isNewRange ? CLUB_QUERIES.BAYS : CLUB_QUERIES.SPACES, 'OVERRIDES', currentCourse.id],
    queryFn: async () => {
      let spacesRes = await (isNewRange
        ? queryBays({ drivingRangeId: currentBayRange?.id })
        : getSpaces({ course: currentCourse.id }))
      const dbSpaces = isNewRange ? spacesRes?.bays : spacesRes

      return [
        { id: -1, name: t('words.all') },
        ...dbSpaces.map((space) => ({
          name: isNewRange ? `${t('words.bay')} ${space.bay_number}` : space.name,
          id: space.uuid,
        })),
      ]
    },
  })

  return (
    <div className={style.timePeriodsFormContainer}>
      <form onSubmit={onFormSubmit}>
        <div className="new-period-form-container">
          <div className={style.teeTimePeriodOptions}>
            <ScheduledDateInput
              errors={errors}
              formState={formState}
              setValue={setValue}
              toggleScheduleTo={toggleScheduleTo}
            />
            <ScheduledTimeInput errors={errors} formState={formState} setValue={setValue} />
            {currentCourse.type !== 'course' && (
              <div>
                <span className={style.optionsText}>{t('words.space')}</span>
                <div className={style.select}>
                  <DropdownSelect
                    values={spacesOptions || []}
                    selectedId={formState.space}
                    initialId={formState.space}
                    onSelect={(uuid) => setValue('space', uuid)}
                    disabled={isFetching}
                    noMargin
                    fixTextLayout
                    noStrikethroughWhenDisabled
                  />
                </div>
              </div>
            )}
            <CategorySelect formState={formState} setValue={setValue} isOverride />
            <DateTimeRangeInput formState={formState} errors={errors} setValue={setValue} />
            <DayInput
              errors={errors}
              formState={formState}
              setValue={setValue}
              daysOptions={daysOptions}
              timeZone={currentCourse.timezone}
            />
            <SlotsField formState={formState} errors={errors} setValue={setValue} />
            <GolfIdSelect formState={formState} setValue={setValue} hasCdhNumber={hasCdhNumber} />
            <PrimeTimeSelect formState={formState} setValue={setValue} />
            <div className={style.submitButton}>
              <button className="system-button primary md-32" onClick={onFormSubmit}>
                {t('editPeriodsNew.save')}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddOverrideForm

const getInitialState = (period) => ({
  isScheduled: false,
  days: 0,
  scheduledDate: formatToDatePicker(period.start_date),
  scheduledTime: formatToTimePicker(period.start_time_from),
  category_id: null,
  start_date: formatToDatePicker(period.start_date),
  end_date: formatToDatePicker(period.start_date),
  start_time_from: formatToTimePicker(period.start_time_from),
  start_time_to: formatToTimePicker(period.start_time_to),
  is_golf_id_required: null,
  is_prime_time: null,
  slots: period.slots,
  space: -1,
})
