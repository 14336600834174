import { DemandStatus } from './types'

export type PriceObject = {
  status: DemandStatus
  price: number
}

const statuses = [DemandStatus.High, DemandStatus.Medium, DemandStatus.Low, DemandStatus.Closed]

const getRandomStatus = (): DemandStatus => {
  return statuses[Math.floor(Math.random() * statuses.length)]
}

const priceStatusMap: { [key: string]: number } = {
  [DemandStatus.High]: 50,
  [DemandStatus.Medium]: 40,
  [DemandStatus.Low]: 25,
}

export const generatePriceList = (length: number): PriceObject[] => {
  return Array.from({ length }, () => {
    const status = getRandomStatus()
    return { price: priceStatusMap[status], status }
  })
}
