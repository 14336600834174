export const isAPhoneNumber = (string: string) => {
  if (!string) return true
  else {
    const re = /^\+(?:[0-9] ?){6,14}[0-9]$/

    return re.test(String(string))
  }
}

export const validateEmail = (email: string) => {
  if (!email) return true
  else {
    const re =
      // eslint-disable-next-line
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return re.test(String(email).toLowerCase())
  }
}

export const isGolfId = (golfId: string) => {
  const re = /^\d{6}-\d{3}$/
  return re.test(golfId)
}
