import { Hydra, PaymentLink } from '@sweetspot/shared/types'
import request, { getBaseUrlPlatform } from '@sweetspot/sweetspot-js/common/functions/request'

export const getPaymentLinks = (
  searchValue: string,
  clubId: number,
  pageParam: number
): Promise<Hydra<PaymentLink>> => {
  const searchValueDecoded = encodeURIComponent(searchValue)
  const url = `${getBaseUrlPlatform()}/payment-links?club=${clubId}&order[payment.state]=desc&order[is_active]=desc&page=${pageParam}&limit=10&search_field=${searchValueDecoded}`

  return request(url, {
    method: 'GET',
    headers: {
      Accept: 'Application/ld+json',
    },
  })
}
